import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_RUN_ANALYSIS = 'GET_RUN_ANALYSIS'
const GET_RUN_ANALYSIS_MORE = 'GET_RUN_ANALYSIS_MORE'
const GET_RUN_ANALYSIS_SUCCESS = 'GET_RUN_ANALYSIS_SUCCESS'
const GET_RUN_ANALYSIS_MORE_SUCCESS = 'GET_RUN_ANALYSIS_MORE_SUCCESS'
const GET_RUN_ANALYSIS_FAILURE = 'GET_RUN_ANALYSIS_FAILURE'
const GET_RUN_ANALYSIS_RESET = 'GET_RUN_ANALYSIS_RESET'

const GET_RUN_ANALYSIS_COUNTS = 'GET_RUN_ANALYSIS_COUNTS'
const GET_RUN_ANALYSIS_COUNTS_SUCCESS = 'GET_RUN_ANALYSIS_COUNTS_SUCCESS'
const GET_RUN_ANALYSIS_COUNTS_RESET = 'GET_RUN_ANALYSIS_COUNTS_RESET'
const GET_RUN_ANALYSIS_COUNTS_FAILURE = 'GET_RUN_ANALYSIS_COUNTS_FAILURE'

const SAVE_RUN_ANALYSIS_COUNTS = 'SAVE_RUN_ANALYSIS_COUNTS'
const SAVE_RUN_ANALYSIS_COUNTS_SUCCESS = 'SAVE_RUN_ANALYSIS_COUNTS_SUCCESS'
const SAVE_RUN_ANALYSIS_COUNTS_RESET = 'SAVE_RUN_ANALYSIS_COUNTS_RESET'
const SAVE_RUN_ANALYSIS_COUNTS_FAILURE = 'SAVE_RUN_ANALYSIS_COUNTS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getRunAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_RUN_ANALYSIS,
    url: `${process.env.apiUrl}v0/search/runanalysis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: payload.is_saved || false, // show notification on success
  message: payload.is_saved ? 'Process to save the list of investigators qualifying this analysis has been commenced. You will be notified via email once the process is completed and the saved results are available by clicking the view icon (eye like icon) next to the analysis name on project summary page.' : '',
  hideNotification: false, // hide notification on error
})

export const getRunAnalysisResetAction = () => ({
  type: GET_RUN_ANALYSIS_RESET,
})

export const getRunAnalysisCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_RUN_ANALYSIS_COUNTS,
    url: `${process.env.apiUrl}v0/search/runanalysiscount`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const saveRunAnalysisCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: SAVE_RUN_ANALYSIS_COUNTS,
    url: `${process.env.apiUrl}v0/search/runanalysiscount`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const saveRunAnalysisCountsResetAction = () => ({
  type: SAVE_RUN_ANALYSIS_COUNTS_RESET,
})

export const getRunAnalysisCountsResetAction = () => ({
  type: GET_RUN_ANALYSIS_COUNTS_RESET,
})

export const getRunAnalysisMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_RUN_ANALYSIS_MORE,
    url: `${process.env.apiUrl}v0/search/runanalysis`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getRunAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RUN_ANALYSIS: {
      if (action.payload.is_saved && !action.meta.source) {
        return {
          data: {
            ...state.data,
            data: [
              ...state.data.data,
            ],
          },
          loading: true,
          flag: false,
          error: false,
        }
      }
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_SUCCESS: {
      if (action.payload.message && !action.meta.source) {
        return {
          data: {
            ...state.data,
            data: [
              ...state.data.data,
            ],
          },
          loading: false,
          flag: true,
          error: false,
          savedResult: true,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_MORE_SUCCESS: {
      return {
        data: {
          ...action.payload,
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_RUN_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export function getRunAnalysisCountsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_RUN_ANALYSIS_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_COUNTS_SUCCESS: {
      if (action.meta.analysis_id) {
        const { analysis_id } = action.meta;
        return {
          data: {
            ...state.data, [analysis_id]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_COUNTS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_RUN_ANALYSIS_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function saveRunAnalysisCountsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SAVE_RUN_ANALYSIS_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case SAVE_RUN_ANALYSIS_COUNTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SAVE_RUN_ANALYSIS_COUNTS_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SAVE_RUN_ANALYSIS_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
