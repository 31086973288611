import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_SAVED_ANALYSIS = 'GET_SAVED_ANALYSIS'
const GET_SAVED_ANALYSIS_MORE = 'GET_SAVED_ANALYSIS_MORE'
const GET_SAVED_ANALYSIS_SUCCESS = 'GET_SAVED_ANALYSIS_SUCCESS'
const GET_SAVED_ANALYSIS_MORE_SUCCESS = 'GET_SAVED_ANALYSIS_MORE_SUCCESS'
const GET_SAVED_ANALYSIS_RESET = 'GET_SAVED_ANALYSIS_RESET'
const GET_SAVED_ANALYSIS_FAILURE = 'GET_SAVED_ANALYSIS_FAILURE'

const GET_SAVED_ANALYSIS_COUNTS = 'GET_SAVED_ANALYSIS_COUNTS'
const GET_SAVED_ANALYSIS_COUNTS_SUCCESS = 'GET_SAVED_ANALYSIS_COUNTS_SUCCESS'
const GET_SAVED_ANALYSIS_COUNTS_RESET = 'GET_SAVED_ANALYSIS_COUNTS_RESET'
const GET_SAVED_ANALYSIS_COUNTS_FAILURE = 'GET_SAVED_ANALYSIS_COUNTS_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getSavedAnalysisAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_ANALYSIS,
    url: `${process.env.apiUrl}v0/search/getAnalysisResult`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSavedAnalysisCountsAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_ANALYSIS_COUNTS,
    url: `${process.env.apiUrl}v0/search/getAnalysisResultCount`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getSavedAnalysisCountsResetAction = () => ({
  type: GET_SAVED_ANALYSIS_COUNTS_RESET,
})

export const getSavedAnalysisResetAction = () => ({
  type: GET_SAVED_ANALYSIS_RESET,
})

export const getSavedAnalysisMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_SAVED_ANALYSIS_MORE,
    url: `${process.env.apiUrl}v0/search/getAnalysisResult`,
    method: 'GET',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getSavedAnalysisReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SAVED_ANALYSIS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_MORE_SUCCESS: {
      return {
        data: {
          data: [{
            ...state.data.data[0],
            result_data: [
              ...state.data.data[0].result_data,
              ...action.payload.data[0].result_data,
            ],
          }],
          total: action.payload.total,
          totalCenters: action.payload.totalCenters,
          totalPiCount: action.payload.totalPiCount,
          comments: action.payload.comments,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_SAVED_ANALYSIS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}

export function getSavedAnalysisCountsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_SAVED_ANALYSIS_COUNTS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_COUNTS_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_COUNTS_RESET: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_SAVED_ANALYSIS_COUNTS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
