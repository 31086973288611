import React, { useEffect, useState } from 'react'
import {
  Button, Popover, Menu, Dropdown, Checkbox, Modal, Radio, Space, Select, Rate, Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import ReadMore from '../../../common/components/ReadMore';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import {
  getRunAnalysisAction,
  getRunAnalysisMoreAction,
  getRunAnalysisResetAction,
  getRunAnalysisCountsAction,
  getRunAnalysisCountsResetAction,
  // saveRunAnalysisCountsAction,
  saveRunAnalysisCountsResetAction,
} from '../RunQuery/logic';
import RunAnalysisMap from '../../../common/components/RunAnalysisMap';
import Bookmark from '../../../common/components/Bookmark';
import Loader from '../../../common/components/Loader';
import AnalysisFilters from './AnalysisFilters';
import {
  shortlistPiAction,
  shortlistPiResetAction,
  deleteshortlistedPiAction,
  deleteshortlistedPiResetAction,
} from './logic';
import './runanalysis.scss'
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';
import StudyModal from '../StudyModal';
import ColumnFilters from './columnFilters';
import SortBy from './SortBy';
import { getFormattedDate, setAnalytics } from '../../../utils';

const hierarchyOptions = [
  {
    title: 'Country',
    value: 'country',
  },
  {
    title: 'Parent institute',
    value: 'parent_institutes',
  },
  {
    title: 'Primary Center',
    value: 'primary_organizations',
  },
  {
    title: 'Principal investigator',
    value: 'pis',
  },
];

const { Option } = Select;

const keys = {
  '# Industry Studies': true,
  'Competing Studies': true,
  'PXL # Studies': true,
  'Enrolled Subjects': true,
  Quality: true,
  'KOL Score': true,
  'Last Trial Start Date': false,
  Center: false,
  Country: false,
  Networks: false,
  'Alliance Type': false,
  'Last Shortlisted On': false,
};

export default function RunAnalysis({ ...props }) {
  const [analysisId, setanalysisId] = useState('')
  const [fromVal, setFromVal] = useState(1);
  const [sizeVal, setSizeVal] = useState(100);
  const runAnalysisData = useSelector((state) => state.runAnalysisData);
  const runAnalysisCountsData = useSelector((state) => state.runAnalysisCountsData);
  const shortlistPiResponse = useSelector((state) => state.shortlistPiResponse);
  const deleteShortlistedPiResponse = useSelector((state) => state.deleteShortlistedPiResponse);
  const [runAnalysisResults, setRunAnalysisResults] = useState([]);
  const [resultsTotal, setResultsTotal] = useState(0);
  const [countsData, setCountsData] = useState([]);

  const [showAlertNotification, setShowAlertNotification] = useState(true);
  const [visible, setVisible] = useState(false);
  const [IsComparisionClicked, setComparisionClicked] = useState(false);
  const [IsSiteComparisionClicked, setSiteComparisionClicked] = useState(false);

  const [checkCounter, setCheckCounter] = useState(0);
  const [compareList, setCompareList] = useState(parse(props.location.search).compareList ? parse(props.location.search).compareList.split(',') : []);
  const [isSelectCommentTypeModalVisible, setIsSelectCommentTypeModalVisible] = useState(false);
  const [commentTypeVal, setCommentTypeVal] = useState('specific');
  const [piForComment, setPiForComment] = useState({});
  const [siteForComment, setSiteForComment] = useState({});
  const [bookmarkedIds, setBookmarkedIds] = useState([]);
  const [unbookmarkedIds, setUnbookmarkedIds] = useState([]);
  const [createdBookmarksAPIres, setCreatedBookmarksAPIres] = useState([]);
  const [runTimePropsComment, setRunTimePropsComment] = useState({
    hideMe: true,
  });
  const [appliedFilters, setAppliedFilters] = useState({
    alliance: 'All',
    is_added: 'All',
    is_commented: 'All',
    quality_rating: undefined,
    tier: undefined,
  })
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);
  const [isSortByPopoverVisible, setIsSortByPopoverVisible] = useState(false);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const [shortlistedPIs, setShortlistedPIs] = useState([]);
  const [deletedShortlistedPIs, setDeletedShortlistedPIs] = useState([]);
  const [shortlistedPIsAPIRes, setShortlistedPIsAPIRes] = useState([]);
  const [hierarchy, setHierarchy] = useState('pis');
  const [showPisOfParent, setShowPisOfParent] = useState('');
  const [showCountryHierarchy, setShowCountryHierarchy] = useState('');
  const [disableComparision, setDisableComparision] = useState(false)
  const [disableSiteComparision, setDisableSiteComparision] = useState(false)

  const [onItmClicked, setOnItmClicked] = useState(false)
  const [onIndustryClicked, setOnIndustryClicked] = useState(false)
  const [onPxlClicked, setOnPxlClicked] = useState(false)

  const [clickedPId, setPId] = useState('')
  const [clickedIId, setIId] = useState('')
  const [clickedPxId, setPxId] = useState('')
  const [industryStudiesSource, setIndustryStudiesSource] = useState('');
  const parsedSearch = parse(props.location.search)
  if (compareList) {
    delete parsedSearch.compareList
  }
  const [displayColumns, setDisplayColumns] = useState({
    ...keys,
  })
  const [showRunAnalysisMap, setShowRunAnalysisMap] = useState(false);
  const [sortObj, setSortObj] = useState({ industry_studies: 'desc' });
  const [searchPIInput, setSearchPIInput] = useState('');
  const [displayingSearchPIResults, setDisplayingSearchPIResults] = useState(false);
  const [displayComplianceConfirm, setDisplayComplianceConfirm] = useState(false);
  const [compliancePreventedPI, setCompliancePreventedPI] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (createBookmark.flag) {
      const temp = [...createdBookmarksAPIres];
      temp.push(createBookmark.data);
      setCreatedBookmarksAPIres(temp);
      dispatch(createBookmarkResetAction())
    }
    if (deleteBookmark.flag) {
      dispatch(deleteBookmarkResetAction())
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  const handleMenuClick = (e, itm) => {
    if (e.key === '1') {
      setIsSelectCommentTypeModalVisible(true);
      setPiForComment(itm);
    }
    if (e.key === '2') {
      setComparisionClicked(true)
      setDisableComparision(false)
      setDisableSiteComparision(true)
    }
  };

  const ResetCompare = () => {
    setCompareList([])
    setCheckCounter(0)
    setComparisionClicked(false)
    setSiteComparisionClicked(false)
    setDisableComparision(false)
    setDisableSiteComparision(false)
    const params = {
      ...parse(location.search),
    }
    delete params.compareFlag;
    delete params.compareSiteFlag;
    delete params.compareList;
    history.replace(`${location.pathname}?${stringify(params)}`);
  }

  const handleSiteMenuClick = (e) => {
    if (e.key === '1') {
      setSiteComparisionClicked(true);
      setDisableComparision(true)
      setDisableSiteComparision(false)
    }
  }

  const onCheckboxChange = (e, selectedPi) => {
    if (e.target.checked) {
      if (checkCounter <= 2) {
        setCompareList((val) => [...val, selectedPi])
        setCheckCounter((c) => c + 1)
      }
    } else {
      setCompareList((val) => val.filter((id) => id !== selectedPi))
      setCheckCounter((c) => c - 1)
    }
  };
  const siteDataMenu = () => (
    <Menu
      onClick={(e) => handleSiteMenuClick(e)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '1',
          label: 'Select for comparision',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
          disabled: checkDisabled() || parse(location.search).compareFlag,
        },
      ]}
    />
  );

  const checkDisabled = () => {
    if (disableComparision === true || disableSiteComparision === true) {
      return true
    }
    return false
  }
  const dataMenu = (itm) => (
    <Menu
      onClick={(e) => handleMenuClick(e, itm)}
      className="project-detail-cardmenu-dropdown"
      items={[
        {
          key: '2',
          label: 'Select for comparision',
          className: 'project-details-dropdown-icon comparision-dropdown-icon',
          disabled: checkDisabled() || parse(location.search).compareSiteFlag,
        },
        {
          key: '4',
          label: 'Compliance Issue',
          className: 'project-details-dropdown-icon compliance-dropdown-icon disabled',
        },
      ]}
    />
  );

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const handleBackButton = () => {
    history.goBack();
  };

  useEffect(() => {
    return () => {
      setIsFilterPopoverVisible(false);
      dispatch(getRunAnalysisResetAction());
      dispatch(getRunAnalysisCountsResetAction());
      dispatch(saveRunAnalysisCountsResetAction());
    };
  }, []);

  useEffect(() => {
    setanalysisId(parse(location.search).analysis_id);
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify(appliedFilters),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }));
    dispatch(getRunAnalysisCountsAction({
      analysis_id: (parse(location.search).analysis_id),
      project_id: (parse(location.search).project_id),
    }));
  }, []);

  const handleOnCompareClick = () => {
    const params = {
      lastUrlPath: `${location.pathname}`,
      locationSearch: stringify(parsedSearch),
      compareList: compareList.toString(),
      project_name: parse(location.search).project_name,
      analysis_name: parse(location.search).analysis_name,
    };
    history.push(`/projects/compareinvestigators?${stringify(params)}`);
  }

  const handleOnSiteCompareClick = () => {
    const params = {
      lastUrlPath: `${location.pathname}`,
      locationSearch: stringify(parsedSearch),
      compareList: compareList.toString(),
      project_name: parse(location.search).project_name,
      analysis_name: parse(location.search).analysis_name,
    };
    history.push(`/projects/comparesites?${stringify(params)}`);
  }

  useEffect(() => {
    if (runAnalysisCountsData.flag) {
      setCountsData(runAnalysisCountsData.data.data);
    }
  }, [JSON.stringify(runAnalysisCountsData)]);

  useEffect(() => {
    if (runAnalysisData.flag) {
      setIsFilterPopoverVisible(false);
      setBookmarkedIds([]);
      setUnbookmarkedIds([]);
      setCreatedBookmarksAPIres([]);
      setShortlistedPIs([]);
      setDeletedShortlistedPIs([]);
      setShortlistedPIsAPIRes([]);
      setRunAnalysisResults(runAnalysisData.data.data);
      setResultsTotal(runAnalysisData.data.total);
    }
  }, [JSON.stringify(runAnalysisData)]);

  useEffect(() => {
    if (shortlistPiResponse.flag) {
      const temp = [...shortlistedPIsAPIRes];
      temp.push(shortlistPiResponse.data.data);
      setShortlistedPIsAPIRes(temp);
      dispatch(shortlistPiResetAction());
    }
  }, [JSON.stringify(shortlistPiResponse)]);

  useEffect(() => {
    if (deleteShortlistedPiResponse.flag) {
      dispatch(deleteshortlistedPiResetAction());
    }
  }, [JSON.stringify(deleteShortlistedPiResponse)]);

  const loadMoreRows = () => {
    setFromVal(fromVal + 1);
    dispatch(getRunAnalysisMoreAction({
      project_id: parse(location.search).project_id,
      from: fromVal + 1,
      size: sizeVal,
      analysis_id: analysisId,
      filters: JSON.stringify(appliedFilters),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }));
  }
  const checkDefaultChecked = (id) => {
    return compareList.includes(id)
  }

  const checkCompareEnable = () => {
    return (compareList.length < 2 || compareList.length > 3)
  }

  const applyColumns = (columnsObj) => {
    setDisplayColumns(columnsObj);
    setVisible(false);
  };

  const checkColumnDisplay = (columnName) => {
    const viewedKeys = Object.keys(displayColumns).filter((key) => displayColumns[key]);
    return viewedKeys.indexOf(columnName) !== -1;
  }

  const handleCommentCancel = () => {
    setPiForComment({});
    setSiteForComment({});
    setIsSelectCommentTypeModalVisible(false);
  }

  const onCommentTypeChange = (e) => {
    if (e.target.value !== commentTypeVal) {
      setCommentTypeVal(e.target.value)
    }
  }

  const handleCommentTypeOk = () => {
    let runtimeProps = {};
    if (piForComment.pi_id) {
      runtimeProps = {
        projectId: parse(location.search).project_id,
        analysisId: parse(location.search).analysis_id,
        piId: piForComment.pi_id,
        hideMe: false,
      };
      if (commentTypeVal === 'specific') {
        runtimeProps.type = 'pi';
        runtimeProps.sitePIExtraType = commentTypeVal;
      } else {
        runtimeProps.type = 'list_pi';
        runtimeProps.sitePIExtraType = commentTypeVal;
      }
    }
    if (siteForComment.site_id) {
      runtimeProps = {
        projectId: parse(location.search).project_id,
        analysisId: parse(location.search).analysis_id,
        siteId: siteForComment.site_id,
        hideMe: false,
      };
      if (commentTypeVal === 'specific') {
        runtimeProps.type = 'site';
        runtimeProps.sitePIExtraType = commentTypeVal;
      } else {
        runtimeProps.type = 'list_site';
        runtimeProps.sitePIExtraType = commentTypeVal;
      }
    }
    setRunTimePropsComment(runtimeProps);
    setIsSelectCommentTypeModalVisible(false);
  }

  const checkConsent = (obj) => {
    const consentVal = obj.consent.split(',');
    if (obj.pi_discontinued_flag === 'Y' || ((consentVal.length && consentVal[0].toLowerCase() === 'no') || (obj.consent.slice(0, 2).toLowerCase() === 'no'))) {
      return false;
    }
    return true;
  }

  const onShortlistClick = (itm) => {
    if (checkConsent(itm)) {
      let temp = [];
      if (shortlistedPIs.length && shortlistedPIs.indexOf(itm.pi_id) !== -1) {
        const apiResIndex = shortlistedPIsAPIRes.findIndex((shortlisted) => shortlisted.pi_id === itm.pi_id);
        temp = [...shortlistedPIs];
        temp.splice(temp.indexOf(itm.pi_id), 1);
        setShortlistedPIs(temp);
        dispatch(deleteshortlistedPiAction({
          params: {
            innoplexus_id: shortlistedPIsAPIRes[apiResIndex].innoplexus_id,
          },
          body: {},
        }))
        setShortlistedPIsAPIRes([...shortlistedPIsAPIRes].splice(apiResIndex, 1));
      } else if (deletedShortlistedPIs.length && deletedShortlistedPIs.indexOf(itm.pi_id) !== -1) {
        temp = [...deletedShortlistedPIs];
        temp.splice(temp.indexOf(itm.pi_id), 1);
        setDeletedShortlistedPIs(temp);
        dispatch(shortlistPiAction({
          pi_id: itm.pi_id,
          project_id: parse(location.search).project_id,
          analysis_id: parse(location.search).analysis_id,
        }))
      } else if (itm.is_shortlisted && itm.is_shortlisted.flag) {
        temp = [...deletedShortlistedPIs];
        temp.push(itm.pi_id);
        setDeletedShortlistedPIs(temp);
        dispatch(deleteshortlistedPiAction({
          params: {
            innoplexus_id: itm.is_shortlisted.innoplexus_id,
          },
          body: {},
        }))
      } else {
        temp = [...shortlistedPIs];
        temp.push(itm.pi_id);
        setShortlistedPIs(temp);
        dispatch(shortlistPiAction({
          pi_id: itm.pi_id,
          project_id: parse(location.search).project_id,
          analysis_id: parse(location.search).analysis_id,
        }))
      }
    }
  }

  const onBookmarkChange = (id, type) => {
    let temp = [];
    if (bookmarkedIds.length && bookmarkedIds.indexOf(id) !== -1) {
      const apiResIndex = createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === id);
      temp = [...bookmarkedIds];
      const temp1 = [...createdBookmarksAPIres]
      temp.splice(temp.indexOf(id), 1);
      setBookmarkedIds(temp);
      temp1.splice(apiResIndex, 1)
      setCreatedBookmarksAPIres(temp1);
    } else if (unbookmarkedIds.length && unbookmarkedIds.indexOf(id) !== -1) {
      temp = [...unbookmarkedIds];
      temp.splice(temp.indexOf(id), 1);
      setUnbookmarkedIds(temp);
    } else if (type === 'create') {
      temp = [...bookmarkedIds];
      temp.push(id);
      setBookmarkedIds(temp);
    } else {
      temp = [...unbookmarkedIds];
      temp.push(id);
      setUnbookmarkedIds(temp);
    }
  }

  const applyFilters = (filtersObj) => {
    setAppliedFilters({ ...appliedFilters, ...filtersObj });
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify({ ...appliedFilters, ...filtersObj }),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }));
    setIsFilterPopoverVisible(false);
    const params = {
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify({ ...appliedFilters, ...filtersObj }),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }
    setAnalytics('analysisResultFilter', 'data', params)
  }

  const applySort = (srtObj) => {
    setSortObj(srtObj)
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify(appliedFilters),
      hierarchy,
      order_by: JSON.stringify(srtObj),
      query: searchPIInput,
    }));
    setIsSortByPopoverVisible(false);
  }

  const resetFilters = () => {
    const temp = {
      alliance: 'All',
      is_added: 'All',
      is_commented: 'All',
      quality_rating: undefined,
      tier: undefined,
    };
    setAppliedFilters(temp);
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify(temp),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }));
    setIsFilterPopoverVisible(false);
  }

  const handleHierarchyChange = (val) => {
    setRunAnalysisResults([]);
    const temp = [...val];
    if (temp.indexOf('primary_organizations') === -1 && temp.indexOf('parent_institutes') !== -1) {
      temp.splice(temp.indexOf('parent_institutes'), 1);
    }
    if (val.length === 1 && val[0] === 'pis') {
      setSizeVal(100);
    } else {
      setSizeVal(25);
    }
    setHierarchy(temp.join(','));
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: (val.length === 1 && val[0] === 'pis') ? 100 : 25,
      filters: JSON.stringify(appliedFilters),
      hierarchy: temp.join(','),
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }));
    const params = {
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: (val.length === 1 && val[0] === 'pis') ? 100 : 25,
      filters: JSON.stringify(appliedFilters),
      hierarchy: temp.join(','),
      order_by: JSON.stringify(sortObj),
      query: searchPIInput,
    }
    setAnalytics('runAnalysisResultsLevelsOfHierarchy', 'data', params)
  }

  const reOrderHierarchy = (str) => {
    const temp = [];
    str.split(',').map((itm) => {
      temp[hierarchyOptions.findIndex((obj) => obj.value === itm)] = itm;
      return null;
    })
    return temp.filter((el) => el).join();
  }

  const onHeaderScroll = () => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = source.scrollLeft;
    });
  }

  const onRowScroll = (e) => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = e.target.scrollLeft;
    });
    source.scrollLeft = e.target.scrollLeft;
  }

  const renderQueries = () => {
    if (countsData.length) {
      return countsData.map((qryItm, index) => (
        <div className="header-card-content" key={index}>
          <div className="card">
            <div className="card-content">
              <div className="card-content-value">
                <div className="card-content-value-title">Query name:</div>
                <div className="card-content-value-desc" title={qryItm.query}>{qryItm.query}</div>
              </div>
              <div className="card-content-value">
                <div className="card-content-value-title">Centers:</div>
                <div className="card-content-value-desc">{qryItm.centers}</div>
              </div>
              <div className="card-content-value">
                <div className="card-content-value-title">Pls:</div>
                <div className="card-content-value-desc">{qryItm.pis}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
    return null;
  }

  const onCompetingClick = (pId) => {
    setOnItmClicked(true)
    setPId(pId)
  }
  const onIndustryClick = (pId, industrySource) => {
    setOnIndustryClicked(true)
    setIId(pId)
    setIndustryStudiesSource(industrySource)
  }
  const onPxlClick = (pId) => {
    setOnPxlClicked(true)
    setPxId(pId)
  }

  const onSearchPIInputChange = (e) => {
    if (e.target.value && e.target.value.trim().length) {
      setSearchPIInput(e.target.value);
    } else {
      setSearchPIInput('');
    }
  }

  const onPISearch = () => {
    if (searchPIInput) {
      setFromVal(1)
      dispatch(getRunAnalysisAction({
        project_id: parse(location.search).project_id,
        analysis_id: (parse(location.search).analysis_id),
        from: 1,
        size: sizeVal,
        filters: JSON.stringify(appliedFilters),
        hierarchy,
        order_by: JSON.stringify(sortObj),
        query: searchPIInput,
      }));
      setDisplayingSearchPIResults(true);
    }
  }

  const onClearSearchPIInput = () => {
    setSearchPIInput('');
    setDisplayingSearchPIResults(false);
    setFromVal(1)
    dispatch(getRunAnalysisAction({
      project_id: parse(location.search).project_id,
      analysis_id: (parse(location.search).analysis_id),
      from: 1,
      size: sizeVal,
      filters: JSON.stringify(appliedFilters),
      hierarchy,
      order_by: JSON.stringify(sortObj),
      query: '',
    }));
  }
  const getCountDetails = () => {
    const orderedHierarchy = reOrderHierarchy(hierarchy);
    console.log('runAnalysisData-->', runAnalysisData)
    switch (orderedHierarchy) {
      case 'country,primary_organizations,pis':
      case 'primary_organizations,pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${runAnalysisData?.data?.totalPiCount} Investigators`}
            {runAnalysisData?.data?.totalCenters > 0 ? `, ${runAnalysisData?.data?.totalCenters} Centers` : null}
          </div>
        );
      }
      case 'country,pis':
      case 'pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${runAnalysisData?.data?.totalPiCount} Investigators`}
          </div>
        );
      }
      case 'country,parent_institutes,primary_organizations,pis':
      case 'parent_institutes,primary_organizations,pis': {
        return (
          <div className="show-doc-data">
            Showing
            {` ${runAnalysisData?.data?.totalPiCount} Investigators`}
            {runAnalysisData?.data?.totalCenters > 0 ? `, ${runAnalysisData?.data?.totalCenters} Centers` : null}
            {runAnalysisData?.data?.totalParent > 0 ? `, ${runAnalysisData?.data?.totalParent} Parent Institutes ` : null}
          </div>
        );
      }
      default: return undefined
    }
  }

  const renderAnalysisRecords = (record, recordIndex) => {
    const orderedHierarchy = reOrderHierarchy(hierarchy);
    switch (orderedHierarchy) {
      case 'primary_organizations,pis': {
        return (
          <div className="run-query-content-section">
            <div className="run-query-content-card card-country-content card">
              <div className="card-content card-country-content-header">
                <div className="body-analysis-section level-header-section">
                  <div className="leftside-column">
                    <div className="fav">
                      <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(record.site_id) !== -1 ? true : unbookmarkedIds.indexOf(record.site_id) !== -1 ? false : (record.isBookmarked && record.isBookmarked.flag)} type="centers" id={record.site_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === record.site_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === record.site_id)].innoplexus_id : unbookmarkedIds.indexOf(record.site_id) !== -1 ? '' : record.isBookmarked && record.isBookmarked.bookmark_id} />
                    </div>
                    <a
                      className="card-content-title"
                      title={record.site_name}
                      target="_blank"
                      href={`/search/deepdives?${stringify({
                        query: record.site_name,
                        id: record.site_id,
                        type: 'SITE',
                        currentTab: record.parent_flag ? 'parent_centers' : 'centers',
                      })}`}
                      rel="noreferrer noopener"
                    >
                      {record.site_name}
                    </a>
                  </div>
                  <div className="table-column">
                    <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                      {checkColumnDisplay('# Industry Studies')
                        ? (
                          <div className="filter-sel-data-value industry-studies">{record.industry_studies}</div>
                        )
                        : null}
                      {checkColumnDisplay('Competing Studies')
                        ? (
                          <div className="filter-sel-data-value center">{record.competing_studies}</div>
                        )
                        : null}
                      {checkColumnDisplay('PXL # Studies')
                        ? (
                          <div className="filter-sel-data-value pxl-studies">{record.pxl_studies}</div>
                        )
                        : null}
                      {checkColumnDisplay('Enrolled Subjects')
                        ? (
                          <div className="filter-sel-data-value enrolled-subjects">{record.enrolled_count}</div>
                        )
                        : null}
                      {checkColumnDisplay('Quality')
                        ? (
                          <div className="filter-sel-data-value quality">
                            <Rate count={3} disabled value={record.quality_rating} />
                          </div>
                        )
                        : null}
                      {checkColumnDisplay('KOL Score')
                        ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        )
                        : null}
                      {checkColumnDisplay('Last Trial Start Date')
                        ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        )
                        : null}
                      {checkColumnDisplay('Center')
                        ? (
                          <div className="filter-sel-data-value center">{record.site_name}</div>
                        )
                        : null}
                      {checkColumnDisplay('Country')
                        ? (
                          <div className="filter-sel-data-value center">{record.country}</div>
                        )
                        : null}
                      {checkColumnDisplay('Alliance Type')
                        ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        )
                        : null}
                      {checkColumnDisplay('Networks')
                        ? (
                          <div className="filter-sel-data-value center">{record.networks && record.networks.length ? record.networks.join('; ') : '-'}</div>
                        )
                        : null}
                      {checkColumnDisplay('Last Shortlisted On')
                        ? (
                          <div className="filter-sel-data-value center">Not applicable</div>
                        )
                        : null}
                    </div>
                  </div>
                  {/* <div className="lastside-column" /> */}
                  <div className="lastside-column">
                    {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                      ? (
                        <div className="compare-checkbox">
                          <div className="checkbox-content">
                            <Checkbox
                              className={checkDefaultChecked(record.site_id) ? 'check' : 'uncheck'}
                              onChange={(e) => onCheckboxChange(e, record.site_id)}
                              checked={checkDefaultChecked(record.site_id)}
                            />
                          </div>
                        </div>
                      ) : (
                        <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                          <Button
                            className="accordion-menu"
                            role="presentation"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <Tooltip title="Kebab menu">
                              <div className="accordion-menu-icon" />
                            </Tooltip>
                          </Button>
                        </Dropdown>
                      )}
                  </div>
                </div>
              </div>
              <div className="pi-name-content">
                <ReadMore limit={5}>
                  {record.pis.map((itm, idx) => {
                    return (
                      <div className="pi-content">
                        <div className="body-analysis-section">
                          <div className="leftside-column">
                            <div className="meta-link">
                              <div className="fav">
                                <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(itm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? false : (itm.isBookmarked && itm.isBookmarked.flag)} type="investigators" id={itm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? '' : itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                              </div>
                            </div>
                            <a
                              className="pi-content-title"
                              title={itm.pi_name}
                              target="_blank"
                              href={`/search/investigatorprofile?${stringify({
                                query: itm.pi_name,
                                id: itm.pi_id,
                                type: 'PI',
                              })}`}
                              rel="noreferrer noopener"
                            >
                              {itm.pi_name}
                            </a>
                            {itm.is_new
                              ? (
                                <div className="new-label">
                                  <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                </div>
                              )
                              : null}
                            {itm.compliance && itm.compliance !== 'No Issues'
                              ? (
                                <div className={`${itm.compliance === 'Compliance preventing participation' ? 'flag filled red' : itm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                  <Popover
                                    overlayClassName="flag-section-content"
                                    placement="bottom"
                                    title={<div className="flag-title">Compliance issue</div>}
                                    content={(
                                      <div className="flag-data">
                                        <div className="flag-data-title-create">
                                          <div className="flag-data-content-value">
                                            {itm.compliance_issue_prevention_flag === 'Y'
                                              ? (
                                                <>
                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issue_preventing_participation}</div>
                                                  <br />
                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                </>
                                              )
                                              : (
                                                <>
                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                </>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    trigger="click"
                                  >
                                    <div className="flag-img" />
                                  </Popover>
                                </div>
                              )
                              : null}
                          </div>
                          <div className="table-column">
                            <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                              {checkColumnDisplay('# Industry Studies')
                                ? (
                                  <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(itm.pi_id, itm.industry_studies_source)}>{itm.industry_studies}</Button>
                                )
                                : null}
                              {checkColumnDisplay('Competing Studies')
                                ? (
                                  <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(itm.pi_id)}>{itm.competing_studies}</Button>
                                )
                                : null}
                              {checkColumnDisplay('PXL # Studies')
                                ? (
                                  <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(itm.pi_id)}>{itm.pxl_studies}</Button>
                                )
                                : null}
                              {checkColumnDisplay('Enrolled Subjects')
                                ? (
                                  <div className="filter-sel-data-value enrolled-subjects">{itm.enrolled_count}</div>
                                )
                                : null}
                              {checkColumnDisplay('Quality')
                                ? (
                                  <div className="filter-sel-data-value quality">
                                    <Rate count={3} disabled value={itm.quality_rating} />
                                  </div>
                                )
                                : null}
                              {checkColumnDisplay('KOL Score')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.cls_kol_score}</div>
                                )
                                : null}
                              {checkColumnDisplay('Last Trial Start Date')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.recent_trial_start_date_ts ? getFormattedDate(itm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                )
                                : null}
                              {checkColumnDisplay('Center')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.site_name}</div>
                                )
                                : null}
                              {checkColumnDisplay('Country')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.country}</div>
                                )
                                : null}
                              {checkColumnDisplay('Alliance Type')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.alliance_type}</div>
                                )
                                : null}
                              {checkColumnDisplay('Networks')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.networks && itm.networks.length ? itm.networks.join('; ') : '-'}</div>
                                )
                                : null}
                              {checkColumnDisplay('Last Shortlisted On')
                                ? (
                                  <div className="filter-sel-data-value center">{itm.is_shortlisted.flag ? getFormattedDate(itm.is_shortlisted.created_on) : '-'}</div>
                                )
                                : null}
                            </div>
                          </div>
                          <div className="lastside-column">
                            {(parse(location.search).compareFlag || IsComparisionClicked)
                              ? (
                                <div className="compare-checkbox">
                                  <div className="checkbox-content">
                                    <Checkbox
                                      className={checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'}
                                      key={idx}
                                      onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                      checked={checkDefaultChecked(itm.pi_id)}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                  <Button
                                    className="accordion-menu"
                                    role="presentation"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Tooltip title="Kebab menu">
                                      <div className="accordion-menu-icon" />
                                    </Tooltip>
                                  </Button>
                                </Dropdown>
                              )}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </ReadMore>
              </div>
            </div>
          </div>
        );
      }

      case 'parent_institutes,primary_organizations,pis': {
        return (
          <div className="parent-site-wrap">
            <div className="run-query-content-section">
              <div className="run-query-content-card card-country-content card">
                <div
                  className="card-content card-country-content-header"
                >
                  <div className="body-analysis-section level-header-section level-two-header-section">
                    <div className={`leftside-column ${showPisOfParent === record.parent_id ? 'dropdown-close' : record.pis.length ? 'dropdown-open' : ''}`}>
                      <a
                        className="card-content-title"
                        title={record.parent_name}
                        target="_blank"
                        href={`/search/deepdives?${stringify({
                          query: record.parent_name,
                          id: record.parent_id,
                          type: 'SITE',
                          currentTab: 'parent_centers',
                        })}`}
                        rel="noreferrer noopener"
                      >
                        {record.parent_name}
                      </a>
                      <span
                        className="dropdown-arrow"
                        role="presentation"
                        onClick={() => {
                          if (showPisOfParent !== record.parent_id) {
                            if (record.pis.length) {
                              setShowPisOfParent(record.parent_id);
                            }
                          } else {
                            setShowPisOfParent('');
                          }
                        }}
                      />
                    </div>
                    <div className="table-column">
                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                        {checkColumnDisplay('# Industry Studies')
                          ? (
                            <div className="filter-sel-data-value industry-studies">{record.industry_studies}</div>
                          )
                          : null}
                        {checkColumnDisplay('Competing Studies')
                          ? (
                            <div className="filter-sel-data-value center">{record.competing_studies}</div>
                          )
                          : null}
                        {checkColumnDisplay('PXL # Studies')
                          ? (
                            <div className="filter-sel-data-value pxl-studies">{record.pxl_studies}</div>
                          )
                          : null}
                        {checkColumnDisplay('Enrolled Subjects')
                          ? (
                            <div className="filter-sel-data-value enrolled-subjects">{record.enrolled_count}</div>
                          )
                          : null}
                        {checkColumnDisplay('Quality')
                          ? (
                            <div className="filter-sel-data-value quality">
                              <Rate count={3} disabled value={record.quality_rating} />
                            </div>
                          )
                          : null}
                        {checkColumnDisplay('KOL Score')
                          ? (
                            <div className="filter-sel-data-value center">Not applicable</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Trial Start Date')
                          ? (
                            <div className="filter-sel-data-value center">Not applicable</div>
                          )
                          : null}
                        {checkColumnDisplay('Center')
                          ? (
                            <div className="filter-sel-data-value center">{record.site_name}</div>
                          )
                          : null}
                        {checkColumnDisplay('Country')
                          ? (
                            <div className="filter-sel-data-value center">{record.country}</div>
                          )
                          : null}
                        {checkColumnDisplay('Alliance Type')
                          ? (
                            <div className="filter-sel-data-value center">Not applicable</div>
                          )
                          : null}
                        {checkColumnDisplay('Networks')
                          ? (
                            <div className="filter-sel-data-value center">{record.networks && record.networks.length ? record.networks.join('; ') : '-'}</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Shortlisted On')
                          ? (
                            <div className="filter-sel-data-value center">Not applicable</div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="lastside-column">
                      {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                        ? (
                          <div className="compare-checkbox">
                            <div className="checkbox-content">
                              <Checkbox
                                className={checkDefaultChecked(record.parent_id) ? 'check' : 'uncheck'}
                                onChange={(e) => onCheckboxChange(e, record.parent_id)}
                                checked={checkDefaultChecked(record.parent_id)}
                              />
                            </div>
                          </div>
                        ) : (
                          <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                            <Button
                              className="accordion-menu"
                              role="presentation"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Tooltip title="Kebab menu">
                                <div className="accordion-menu-icon" />
                              </Tooltip>
                            </Button>
                          </Dropdown>
                        )}
                    </div>
                  </div>
                </div>
                {showPisOfParent === record.parent_id && record.pis.length
                  ? (
                    <div className="pi-name-content">
                      <ReadMore limit={5}>
                        {record.pis.map((itm, idx) => {
                          return (
                            <div className="pi-content">
                              <div className="body-analysis-section">
                                <div className="leftside-column">
                                  <div className="meta-link">
                                    <div className="fav">
                                      <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(itm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? false : (itm.isBookmarked && itm.isBookmarked.flag)} type="investigators" id={itm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? '' : itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                                    </div>
                                  </div>
                                  <a
                                    className="pi-content-title"
                                    title={itm.pi_name}
                                    target="_blank"
                                    href={`/search/investigatorprofile?${stringify({
                                      query: itm.pi_name,
                                      id: itm.pi_id,
                                      type: 'PI',
                                    })}`}
                                    rel="noreferrer noopener"
                                  >
                                    {itm.pi_name}
                                  </a>
                                  {itm.is_new
                                    ? (
                                      <div className="new-label">
                                        <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                      </div>
                                    )
                                    : null}
                                  {itm.compliance && itm.compliance !== 'No Issues'
                                    ? (
                                      <div className={`${itm.compliance === 'Compliance preventing participation' ? 'flag filled red' : itm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                        <Popover
                                          overlayClassName="flag-section-content"
                                          placement="bottom"
                                          title={<div className="flag-title">Compliance issue</div>}
                                          content={(
                                            <div className="flag-data">
                                              <div className="flag-data-title-create">
                                                <div className="flag-data-content-value">
                                                  {itm.compliance_issue_prevention_flag === 'Y'
                                                    ? (
                                                      <>
                                                        <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issue_preventing_participation}</div>
                                                        <br />
                                                        <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                      </>
                                                    )
                                                    : (
                                                      <>
                                                        <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                      </>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          trigger="click"
                                        >
                                          <div className="flag-img" />
                                        </Popover>
                                      </div>
                                    )
                                    : null}
                                </div>
                                <div className="table-column">
                                  <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                    {checkColumnDisplay('# Industry Studies')
                                      ? (
                                        <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(itm.pi_id, itm.industry_studies_source)}>{itm.industry_studies}</Button>
                                      )
                                      : null}
                                    {checkColumnDisplay('Competing Studies')
                                      ? (
                                        <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(itm.pi_id)}>{itm.competing_studies}</Button>
                                      )
                                      : null}
                                    {checkColumnDisplay('PXL # Studies')
                                      ? (
                                        <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(itm.pi_id)}>{itm.pxl_studies}</Button>
                                      )
                                      : null}
                                    {checkColumnDisplay('Enrolled Subjects')
                                      ? (
                                        <div className="filter-sel-data-value enrolled-subjects">{itm.enrolled_count}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Quality')
                                      ? (
                                        <div className="filter-sel-data-value quality">
                                          <Rate count={3} disabled value={itm.quality_rating} />
                                        </div>
                                      )
                                      : null}
                                    {checkColumnDisplay('KOL Score')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.cls_kol_score}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Last Trial Start Date')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.recent_trial_start_date_ts ? getFormattedDate(itm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Center')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.site_name}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Country')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.country}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Alliance Type')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.alliance_type}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Networks')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.networks && itm.networks.length ? itm.networks.join('; ') : '-'}</div>
                                      )
                                      : null}
                                    {checkColumnDisplay('Last Shortlisted On')
                                      ? (
                                        <div className="filter-sel-data-value center">{itm.is_shortlisted.flag ? getFormattedDate(itm.is_shortlisted.created_on) : '-'}</div>
                                      )
                                      : null}
                                  </div>
                                </div>
                                <div className="lastside-column">
                                  {(parse(location.search).compareFlag || IsComparisionClicked)
                                    ? (
                                      <div className="compare-checkbox">
                                        <div className="checkbox-content">
                                          <Checkbox
                                            className={checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'}
                                            key={idx}
                                            onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                            checked={checkDefaultChecked(itm.pi_id)}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                        <Button
                                          className="accordion-menu"
                                          role="presentation"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <Tooltip title="Kebab menu">
                                            <div className="accordion-menu-icon" />
                                          </Tooltip>
                                        </Button>
                                      </Dropdown>
                                    )}
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </ReadMore>
                    </div>
                  )
                  : null}
              </div>
            </div>
            {record.primary_organizations && record.primary_organizations.length
              ? (
                <>
                  {record.primary_organizations.map((recod, indexVal) => (
                    <div className="run-query-content-section run-query-content-section-child" key={indexVal}>
                      <div className="run-query-content-card card-country-content card">
                        <div className="card-content card-country-content-header">
                          <div className="body-analysis-section level-middle-header-section">
                            <div className="leftside-column">
                              <div className="fav">
                                <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(recod.site_id) !== -1 ? true : unbookmarkedIds.indexOf(recod.site_id) !== -1 ? false : (recod.isBookmarked && recod.isBookmarked.flag)} type="centers" id={recod.site_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === recod.site_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === recod.site_id)].innoplexus_id : unbookmarkedIds.indexOf(recod.site_id) !== -1 ? '' : recod.isBookmarked && recod.isBookmarked.bookmark_id} />
                              </div>
                              <a
                                className="card-content-title"
                                title={recod.site_name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: recod.site_name,
                                  id: recod.site_id,
                                  type: 'SITE',
                                  currentTab: recod.parent_flag ? 'parent_centers' : 'centers',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {recod.site_name}
                              </a>
                            </div>
                            <div className="table-column">
                              <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                {checkColumnDisplay('# Industry Studies')
                                  ? (
                                    <div className="filter-sel-data-value industry-studies">{recod.industry_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Competing Studies')
                                  ? (
                                    <div className="filter-sel-data-value center">{recod.competing_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('PXL # Studies')
                                  ? (
                                    <div className="filter-sel-data-value pxl-studies">{recod.pxl_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Enrolled Subjects')
                                  ? (
                                    <div className="filter-sel-data-value enrolled-subjects">{recod.enrolled_count}</div>
                                  )
                                  : null}

                                {checkColumnDisplay('Quality')
                                  ? (
                                    <div className="filter-sel-data-value quality">
                                      <Rate count={3} disabled value={recod.quality_rating} />
                                    </div>
                                  )
                                  : null}
                                {checkColumnDisplay('KOL Score')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Last Trial Start Date')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Center')
                                  ? (
                                    <div className="filter-sel-data-value center">{recod.site_name}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Country')
                                  ? (
                                    <div className="filter-sel-data-value center">{recod.country}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Alliance Type')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Networks')
                                  ? (
                                    <div className="filter-sel-data-value center">{recod.networks && recod.networks.length ? recod.networks.join('; ') : '-'}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Last Shortlisted On')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                              </div>
                            </div>
                            <div className="lastside-column">
                              {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                                ? (
                                  <div className="compare-checkbox">
                                    <div className="checkbox-content">
                                      <Checkbox
                                        className={checkDefaultChecked(recod.site_id) ? 'check' : 'uncheck'}
                                        onChange={(e) => onCheckboxChange(e, recod.site_id)}
                                        checked={checkDefaultChecked(recod.site_id)}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="pi-name-content">
                          <ReadMore limit={5}>
                            {recod.pis.map((piitm, idy) => {
                              return (
                                <div className="pi-content" key={idy}>
                                  <div className="body-analysis-section">
                                    <div className="leftside-column">
                                      <div className="meta-link">
                                        <div className="fav">
                                          <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(piitm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(piitm.pi_id) !== -1 ? false : (piitm.isBookmarked && piitm.isBookmarked.flag)} type="investigators" id={piitm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === piitm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === piitm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(piitm.pi_id) !== -1 ? '' : piitm.isBookmarked && piitm.isBookmarked.bookmark_id} />
                                        </div>
                                      </div>
                                      <a
                                        className="pi-content-title"
                                        title={piitm.pi_name}
                                        target="_blank"
                                        href={`/search/investigatorprofile?${stringify({
                                          query: piitm.pi_name,
                                          id: piitm.pi_id,
                                          type: 'PI',
                                        })}`}
                                        rel="noreferrer noopener"
                                      >
                                        {piitm.pi_name}
                                      </a>
                                      {piitm.is_new
                                        ? (
                                          <div className="new-label">
                                            <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                          </div>
                                        )
                                        : null}
                                      {piitm.compliance && piitm.compliance !== 'No Issues'
                                        ? (
                                          <div className={`${piitm.compliance === 'Compliance preventing participation' ? 'flag filled red' : piitm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                            <Popover
                                              overlayClassName="flag-section-content"
                                              placement="bottom"
                                              title={<div className="flag-title">Compliance issue</div>}
                                              content={(
                                                <div className="flag-data">
                                                  <div className="flag-data-title-create">
                                                    <div className="flag-data-content-value">
                                                      {piitm.compliance_issue_prevention_flag === 'Y'
                                                        ? (
                                                          <>
                                                            <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issue_preventing_participation}</div>
                                                            <br />
                                                            <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issues}</div>
                                                          </>
                                                        )
                                                        : (
                                                          <>
                                                            <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issues}</div>
                                                          </>
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              trigger="click"
                                            >
                                              <div className="flag-img" />
                                            </Popover>
                                          </div>
                                        )
                                        : null}
                                    </div>
                                    <div className="table-column">
                                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                        {checkColumnDisplay('# Industry Studies')
                                          ? (
                                            <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(piitm.pi_id, piitm.industry_studies_source)}>{piitm.industry_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('Competing Studies')
                                          ? (
                                            <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(piitm.pi_id)}>{piitm.competing_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('PXL # Studies')
                                          ? (
                                            <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(piitm.pi_id)}>{piitm.pxl_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('Enrolled Subjects')
                                          ? (
                                            <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Quality')
                                          ? (
                                            <div className="filter-sel-data-value quality">
                                              <Rate count={3} disabled value={piitm.quality_rating} />
                                            </div>
                                          )
                                          : null}
                                        {checkColumnDisplay('KOL Score')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.cls_kol_score}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Last Trial Start Date')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.recent_trial_start_date_ts ? getFormattedDate(piitm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Center')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.site_name}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Country')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.country}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Alliance Type')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.alliance_type}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Networks')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Last Shortlisted On')
                                          ? (
                                            <div className="filter-sel-data-value center">{piitm.is_shortlisted.flag ? getFormattedDate(piitm.is_shortlisted.created_on) : '-'}</div>
                                          )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="lastside-column">
                                      {(parse(location.search).compareFlag || IsComparisionClicked)
                                        ? (
                                          <div className="compare-checkbox">
                                            <div className="checkbox-content">
                                              <Checkbox
                                                className={checkDefaultChecked(piitm.pi_id) ? 'check' : 'uncheck'}
                                                key={idy}
                                                onChange={(e) => onCheckboxChange(e, piitm.pi_id)}
                                                checked={checkDefaultChecked(piitm.pi_id)}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <Dropdown overlay={() => dataMenu(piitm)} trigger={['click']}>
                                            <Button
                                              className="accordion-menu"
                                              role="presentation"
                                              onClick={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <Tooltip title="Kebab menu">
                                                <div className="accordion-menu-icon" />
                                              </Tooltip>
                                            </Button>
                                          </Dropdown>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </ReadMore>
                        </div>

                      </div>
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        );
      }

      case 'country,parent_institutes,primary_organizations,pis': {
        return (
          <div className="country-runanalysis-wrap" key={recordIndex}>
            <div className="card-content-title" style={{ display: 'flex', alignItems: 'center' }}>
              <a
                title={record.country}
                target="_blank"
                href={`/search/countryprofile?${stringify({
                  country: record.country,
                  type: 'COUNTRY',
                  projectId: parse(location.search).project_id,
                  analysisId: parse(location.search).analysis_id,
                })}`}
                rel="noreferrer noopener"
              >
                <div
                  className="country-name"
                >
                  <span className="country-name-title">{record.country[0].country}</span>
                </div>
              </a>
              <div
                className="country-name-no-arrow"
                role="presentation"
                onClick={() => {
                  if (showCountryHierarchy !== record.country) {
                    setShowCountryHierarchy(record.country);
                  } else {
                    setShowCountryHierarchy('');
                  }
                }}
              >
                <span className={`country-name-title ${showCountryHierarchy === record.country ? 'dropdown-close' : 'dropdown-open'}`}>
                  <span
                    className="dropdown-arrow"
                  />
                </span>
              </div>
            </div>
            {showCountryHierarchy === record.country && record.parent_institutes && record.parent_institutes.length
              ? (
                <>
                  {record.parent_institutes.map((rcd, indexv) => (
                    <div className="parent-site-wrap" key={indexv}>
                      <div className="run-query-content-section">
                        <div className="run-query-content-card card-country-content card">
                          <div
                            className="card-content card-country-content-header"
                          >
                            <div className="body-analysis-section level-two-header-section">
                              <div className={`leftside-column ${showPisOfParent === rcd.parent_id ? 'dropdown-close' : rcd.pis.length ? 'dropdown-open' : ''}`}>
                                <a
                                  className="card-content-title"
                                  title={rcd.parent_name}
                                  target="_blank"
                                  href={`/search/deepdives?${stringify({
                                    query: rcd.parent_name,
                                    id: rcd.parent_id,
                                    type: 'SITE',
                                    currentTab: 'parent_centers',
                                  })}`}
                                  rel="noreferrer noopener"
                                >
                                  {rcd.parent_name}
                                </a>
                                <span
                                  className="dropdown-arrow"
                                  role="presentation"
                                  onClick={() => {
                                    if (showPisOfParent !== rcd.parent_id) {
                                      if (rcd.pis.length) {
                                        setShowPisOfParent(rcd.parent_id);
                                      }
                                    } else {
                                      setShowPisOfParent('');
                                    }
                                  }}
                                />
                              </div>
                              <div className="table-column">
                                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                  {checkColumnDisplay('# Industry Studies')
                                    ? (
                                      <div className="filter-sel-data-value industry-studies">{rcd.industry_studies}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Competing Studies')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.competing_studies}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('PXL # Studies')
                                    ? (
                                      <div className="filter-sel-data-value pxl-studies">{rcd.pxl_studies}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Enrolled Subjects')
                                    ? (
                                      <div className="filter-sel-data-value enrolled-subjects">{rcd.enrolled_count}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Quality')
                                    ? (
                                      <div className="filter-sel-data-value quality">
                                        <Rate count={3} disabled value={rcd.quality_rating} />
                                      </div>
                                    )
                                    : null}
                                  {checkColumnDisplay('KOL Score')
                                    ? (
                                      <div className="filter-sel-data-value center">Not applicable</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Last Trial Start Date')
                                    ? (
                                      <div className="filter-sel-data-value center">Not applicable</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Center')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.site_name}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Country')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.country}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Alliance Type')
                                    ? (
                                      <div className="filter-sel-data-value center">Not applicable</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Networks')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.networks && rcd.networks.length ? rcd.networks.join('; ') : '-'}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Last Shortlisted On')
                                    ? (
                                      <div className="filter-sel-data-value center">Not applicable</div>
                                    )
                                    : null}
                                </div>
                              </div>
                              <div className="lastside-column">
                                {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                                  ? (
                                    <div className="compare-checkbox">
                                      <div className="checkbox-content">
                                        <Checkbox
                                          className={checkDefaultChecked(rcd.parent_id) ? 'check' : 'uncheck'}
                                          onChange={(e) => onCheckboxChange(e, rcd.parent_id)}
                                          checked={checkDefaultChecked(rcd.parent_id)}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                      <Button
                                        className="accordion-menu"
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        <Tooltip title="Kebab menu">
                                          <div className="accordion-menu-icon" />
                                        </Tooltip>
                                      </Button>
                                    </Dropdown>
                                  )}
                              </div>
                            </div>
                          </div>
                          {showPisOfParent === rcd.parent_id && rcd.pis.length
                            ? (
                              <div className="pi-name-content">
                                <ReadMore limit={5}>
                                  {rcd.pis.map((itm, idx) => {
                                    return (
                                      <div className="pi-content">
                                        <div className="body-analysis-section">
                                          <div className="leftside-column">
                                            <div className="meta-link">
                                              <div className="fav">
                                                <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(itm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? false : (itm.isBookmarked && itm.isBookmarked.flag)} type="investigators" id={itm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? '' : itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                                              </div>
                                            </div>
                                            <a
                                              className="pi-content-title"
                                              title={itm.pi_name}
                                              target="_blank"
                                              href={`/search/investigatorprofile?${stringify({
                                                query: itm.pi_name,
                                                id: itm.pi_id,
                                                type: 'PI',
                                              })}`}
                                              rel="noreferrer noopener"
                                            >
                                              {itm.pi_name}
                                            </a>
                                            {itm.is_new
                                              ? (
                                                <div className="new-label">
                                                  <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                                </div>
                                              )
                                              : null}
                                            {itm.compliance && itm.compliance !== 'No Issues'
                                              ? (
                                                <div className={`${itm.compliance === 'Compliance preventing participation' ? 'flag filled red' : itm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                                  <Popover
                                                    overlayClassName="flag-section-content"
                                                    placement="bottom"
                                                    title={<div className="flag-title">Compliance issue</div>}
                                                    content={(
                                                      <div className="flag-data">
                                                        <div className="flag-data-title-create">
                                                          <div className="flag-data-content-value">
                                                            {itm.compliance_issue_prevention_flag === 'Y'
                                                              ? (
                                                                <>
                                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issue_preventing_participation}</div>
                                                                  <br />
                                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                                </>
                                                              )
                                                              : (
                                                                <>
                                                                  <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                                </>
                                                              )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                    trigger="click"
                                                  >
                                                    <div className="flag-img" />
                                                  </Popover>
                                                </div>
                                              )
                                              : null}
                                          </div>
                                          <div className="table-column">
                                            <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                              {checkColumnDisplay('# Industry Studies')
                                                ? (
                                                  <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(itm.pi_id, itm.industry_studies_source)}>{itm.industry_studies}</Button>
                                                )
                                                : null}
                                              {checkColumnDisplay('Competing Studies')
                                                ? (
                                                  <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(itm.pi_id)}>{itm.competing_studies}</Button>
                                                )
                                                : null}
                                              {checkColumnDisplay('PXL # Studies')
                                                ? (
                                                  <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(itm.pi_id)}>{itm.pxl_studies}</Button>
                                                )
                                                : null}
                                              {checkColumnDisplay('Enrolled Subjects')
                                                ? (
                                                  <div className="filter-sel-data-value enrolled-subjects">{itm.enrolled_count}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Quality')
                                                ? (
                                                  <div className="filter-sel-data-value quality">
                                                    <Rate count={3} disabled value={itm.quality_rating} />
                                                  </div>
                                                )
                                                : null}
                                              {checkColumnDisplay('KOL Score')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.cls_kol_score}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Last Trial Start Date')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.recent_trial_start_date_ts ? getFormattedDate(itm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Center')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.site_name}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Country')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.country}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Alliance Type')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.alliance_type}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Networks')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.networks && itm.networks.length ? itm.networks.join('; ') : '-'}</div>
                                                )
                                                : null}
                                              {checkColumnDisplay('Last Shortlisted On')
                                                ? (
                                                  <div className="filter-sel-data-value center">{itm.is_shortlisted.flag ? getFormattedDate(itm.is_shortlisted.created_on) : '-'}</div>
                                                )
                                                : null}
                                            </div>
                                          </div>
                                          <div className="lastside-column">
                                            {(parse(location.search).compareFlag || IsComparisionClicked)
                                              ? (
                                                <div className="compare-checkbox">
                                                  <div className="checkbox-content">
                                                    <Checkbox
                                                      className={checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'}
                                                      key={idx}
                                                      onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                                      checked={checkDefaultChecked(itm.pi_id)}
                                                    />
                                                  </div>
                                                </div>
                                              ) : (
                                                <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                                  <Button
                                                    className="accordion-menu"
                                                    role="presentation"
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                    }}
                                                  >
                                                    <Tooltip title="Kebab menu">
                                                      <div className="accordion-menu-icon" />
                                                    </Tooltip>
                                                  </Button>
                                                </Dropdown>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </ReadMore>
                              </div>
                            )
                            : null}
                        </div>
                      </div>
                      {rcd.primary_organizations && rcd.primary_organizations.length
                        ? (
                          <>
                            {rcd.primary_organizations.map((recod, indexVal) => (
                              <div className="run-query-content-section run-query-content-section-child" key={indexVal}>
                                <div className="run-query-content-card card-country-content card">
                                  <div className="card-content card-country-content-header">
                                    <div className="body-analysis-section level-two-header-section">
                                      <div className="leftside-column">
                                        <div className="fav">
                                          <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(recod.site_id) !== -1 ? true : unbookmarkedIds.indexOf(recod.site_id) !== -1 ? false : (recod.isBookmarked && recod.isBookmarked.flag)} type="centers" id={recod.site_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === recod.site_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === recod.site_id)].innoplexus_id : unbookmarkedIds.indexOf(recod.site_id) !== -1 ? '' : recod.isBookmarked && recod.isBookmarked.bookmark_id} />
                                        </div>
                                        <a
                                          className="card-content-title"
                                          title={recod.site_name}
                                          target="_blank"
                                          href={`/search/deepdives?${stringify({
                                            query: recod.site_name,
                                            id: recod.site_id,
                                            type: 'SITE',
                                            currentTab: recod.parent_flag ? 'parent_centers' : 'centers',
                                          })}`}
                                          rel="noreferrer noopener"
                                        >
                                          {recod.site_name}
                                        </a>
                                      </div>
                                      <div className="table-column">
                                        <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                          {checkColumnDisplay('# Industry Studies')
                                            ? (
                                              <div className="filter-sel-data-value industry-studies">{recod.industry_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Competing Studies')
                                            ? (
                                              <div className="filter-sel-data-value center">{recod.competing_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('PXL # Studies')
                                            ? (
                                              <div className="filter-sel-data-value pxl-studies">{recod.pxl_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Enrolled Subjects')
                                            ? (
                                              <div className="filter-sel-data-value enrolled-subjects">{recod.enrolled_count}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Quality')
                                            ? (
                                              <div className="filter-sel-data-value quality">
                                                <Rate count={3} disabled value={recod.quality_rating} />
                                              </div>
                                            )
                                            : null}
                                          {checkColumnDisplay('KOL Score')
                                            ? (
                                              <div className="filter-sel-data-value center">Not applicable</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Last Trial Start Date')
                                            ? (
                                              <div className="filter-sel-data-value center">Not applicable</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Center')
                                            ? (
                                              <div className="filter-sel-data-value center">{recod.site_name}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Country')
                                            ? (
                                              <div className="filter-sel-data-value center">{recod.country}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Alliance Type')
                                            ? (
                                              <div className="filter-sel-data-value center">Not applicable</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Networks')
                                            ? (
                                              <div className="filter-sel-data-value center">{recod.networks && recod.networks.length ? recod.networks.join('; ') : '-'}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Last Shortlisted On')
                                            ? (
                                              <div className="filter-sel-data-value center">Not applicable</div>
                                            )
                                            : null}
                                        </div>
                                      </div>
                                      <div className="lastside-column">
                                        {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                                          ? (
                                            <div className="compare-checkbox">
                                              <div className="checkbox-content">
                                                <Checkbox
                                                  className={checkDefaultChecked(recod.site_id) ? 'check' : 'uncheck'}
                                                  onChange={(e) => onCheckboxChange(e, recod.site_id)}
                                                  checked={checkDefaultChecked(recod.site_id)}
                                                />
                                              </div>
                                            </div>
                                          ) : (
                                            <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                              <Button
                                                className="accordion-menu"
                                                role="presentation"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                }}
                                              >
                                                <Tooltip title="Kebab menu">
                                                  <div className="accordion-menu-icon" />
                                                </Tooltip>
                                              </Button>
                                            </Dropdown>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pi-name-content">
                                    <ReadMore limit={5}>
                                      {recod.pis.map((piitm, idy) => {
                                        return (
                                          <div className="pi-content" key={idy}>
                                            <div className="body-analysis-section">
                                              <div className="leftside-column">
                                                <div className="meta-link">
                                                  <div className="fav">
                                                    <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(piitm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(piitm.pi_id) !== -1 ? false : (piitm.isBookmarked && piitm.isBookmarked.flag)} type="investigators" id={piitm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === piitm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === piitm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(piitm.pi_id) !== -1 ? '' : piitm.isBookmarked && piitm.isBookmarked.bookmark_id} />
                                                  </div>
                                                </div>
                                                <a
                                                  className="pi-content-title"
                                                  title={piitm.pi_name}
                                                  target="_blank"
                                                  href={`/search/investigatorprofile?${stringify({
                                                    query: piitm.pi_name,
                                                    id: piitm.pi_id,
                                                    type: 'PI',
                                                  })}`}
                                                  rel="noreferrer noopener"
                                                >
                                                  {piitm.pi_name}
                                                </a>
                                                {piitm.is_new
                                                  ? (
                                                    <div className="new-label">
                                                      <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                                    </div>
                                                  )
                                                  : null}
                                                {piitm.compliance && piitm.compliance !== 'No Issues'
                                                  ? (
                                                    <div className={`${piitm.compliance === 'Compliance preventing participation' ? 'flag filled red' : piitm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                                      <Popover
                                                        overlayClassName="flag-section-content"
                                                        placement="bottom"
                                                        title={<div className="flag-title">Compliance issue</div>}
                                                        content={(
                                                          <div className="flag-data">
                                                            <div className="flag-data-title-create">
                                                              <div className="flag-data-content-value">
                                                                {piitm.compliance_issue_prevention_flag === 'Y'
                                                                  ? (
                                                                    <>
                                                                      <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issue_preventing_participation}</div>
                                                                      <br />
                                                                      <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issues}</div>
                                                                    </>
                                                                  )
                                                                  : (
                                                                    <>
                                                                      <div style={{ 'white-space': 'pre-line' }}>{piitm.compliance_issues}</div>
                                                                    </>
                                                                  )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        )}
                                                        trigger="click"
                                                      >
                                                        <div className="flag-img" />
                                                      </Popover>
                                                    </div>
                                                  )
                                                  : null}
                                              </div>
                                              <div className="table-column">
                                                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                                  {checkColumnDisplay('# Industry Studies')
                                                    ? (
                                                      <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(piitm.pi_id, piitm.industry_studies_source)}>{piitm.industry_studies}</Button>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Competing Studies')
                                                    ? (
                                                      <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(piitm.pi_id)}>{piitm.competing_studies}</Button>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('PXL # Studies')
                                                    ? (
                                                      <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(piitm.pi_id)}>{piitm.pxl_studies}</Button>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Enrolled Subjects')
                                                    ? (
                                                      <div className="filter-sel-data-value enrolled-subjects">{piitm.enrolled_count}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Quality')
                                                    ? (
                                                      <div className="filter-sel-data-value quality">
                                                        <Rate count={3} disabled value={piitm.quality_rating} />
                                                      </div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('KOL Score')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.cls_kol_score}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Last Trial Start Date')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.recent_trial_start_date_ts ? getFormattedDate(piitm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Center')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.site_name}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Country')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.country}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Alliance Type')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.alliance_type}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Networks')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.networks && piitm.networks.length ? piitm.networks.join('; ') : '-'}</div>
                                                    )
                                                    : null}
                                                  {checkColumnDisplay('Last Shortlisted On')
                                                    ? (
                                                      <div className="filter-sel-data-value center">{piitm.is_shortlisted.flag ? getFormattedDate(piitm.is_shortlisted.created_on) : '-'}</div>
                                                    )
                                                    : null}
                                                </div>
                                              </div>
                                              <div className="lastside-column">
                                                {(parse(location.search).compareFlag || IsComparisionClicked)
                                                  ? (
                                                    <div className="compare-checkbox">
                                                      <div className="checkbox-content">
                                                        <Checkbox
                                                          className={checkDefaultChecked(piitm.pi_id) ? 'check' : 'uncheck'}
                                                          key={idy}
                                                          onChange={(e) => onCheckboxChange(e, piitm.pi_id)}
                                                          checked={checkDefaultChecked(piitm.pi_id)}
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <Dropdown overlay={() => dataMenu(piitm)} trigger={['click']}>
                                                      <Button
                                                        className="accordion-menu"
                                                        role="presentation"
                                                        onClick={(e) => {
                                                          e.preventDefault();
                                                        }}
                                                      >
                                                        <Tooltip title="Kebab menu">

                                                          <div className="accordion-menu-icon" />
                                                        </Tooltip>
                                                      </Button>
                                                    </Dropdown>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </ReadMore>
                                  </div>

                                </div>
                              </div>
                            ))}
                          </>
                        )
                        : null}
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        );
      }

      case 'pis': {
        return (
          <div className="run-query-content-section">
            <div className="run-query-content-card card-country-content card">
              <div className="pi-name-content">
                <div className="pi-content">
                  <div className="body-analysis-section">
                    <div className="leftside-column">
                      <div className="meta-link">
                        <div className="fav">
                          <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(record.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(record.pi_id) !== -1 ? false : (record.isBookmarked && record.isBookmarked.flag)} type="investigators" id={record.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === record.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === record.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(record.pi_id) !== -1 ? '' : record.isBookmarked && record.isBookmarked.bookmark_id} />
                        </div>
                      </div>
                      <a
                        className="pi-content-title"
                        title={record.pi_name}
                        target="_blank"
                        href={`/search/investigatorprofile?${stringify({
                          query: record.pi_name,
                          id: record.pi_id,
                          type: 'PI',
                        })}`}
                        rel="noreferrer noopener"
                      >
                        {record.pi_name}
                      </a>
                      {record.is_new
                        ? (
                          <div className="new-label">
                            <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                          </div>
                        )
                        : null}
                      {record.compliance && record.compliance !== 'No Issues'
                        ? (
                          <div className={`${record.compliance === 'Compliance preventing participation' ? 'flag filled red' : record.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                            <Popover
                              overlayClassName="flag-section-content"
                              placement="bottom"
                              title={<div className="flag-title">Compliance issue</div>}
                              content={(
                                <div className="flag-data">
                                  <div className="flag-data-title-create">
                                    <div className="flag-data-content-value">
                                      {record.compliance_issue_prevention_flag === 'Y'
                                        ? (
                                          <>
                                            <div style={{ 'white-space': 'pre-line' }}>{record.compliance_issue_preventing_participation}</div>
                                            <br />
                                            <div style={{ 'white-space': 'pre-line' }}>{record.compliance_issues}</div>
                                          </>
                                        )
                                        : (
                                          <>
                                            <div style={{ 'white-space': 'pre-line' }}>{record.compliance_issues}</div>
                                          </>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )}
                              trigger="click"
                            >
                              <div className="flag-img" />
                            </Popover>
                          </div>
                        )
                        : null}
                    </div>
                    <div className="table-column">
                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                        {checkColumnDisplay('# Industry Studies')
                          ? (
                            <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(record.pi_id, record.industry_studies_source)}>{record.industry_studies}</Button>
                          )
                          : null}
                        {checkColumnDisplay('Competing Studies')
                          ? (
                            <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(record.pi_id)}>{record.competing_studies}</Button>
                          )
                          : null}
                        {checkColumnDisplay('PXL # Studies')
                          ? (
                            <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(record.pi_id)}>{record.pxl_studies}</Button>
                          )
                          : null}
                        {checkColumnDisplay('Enrolled Subjects')
                          ? (
                            <div className="filter-sel-data-value enrolled-subjects">{record.enrolled_count}</div>
                          )
                          : null}
                        {checkColumnDisplay('Quality')
                          ? (
                            <div className="filter-sel-data-value quality">
                              <Rate count={3} disabled value={record.quality_rating} />
                            </div>
                          )
                          : null}
                        {checkColumnDisplay('KOL Score')
                          ? (
                            <div className="filter-sel-data-value center">{record.cls_kol_score}</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Trial Start Date')
                          ? (
                            <div className="filter-sel-data-value center">{record.recent_trial_start_date_ts ? getFormattedDate(record.recent_trial_start_date_ts * 1000) : '-'}</div>
                          )
                          : null}
                        {checkColumnDisplay('Center')
                          ? (
                            <div className="filter-sel-data-value center">{record.site_name}</div>
                          )
                          : null}
                        {checkColumnDisplay('Country')
                          ? (
                            <div className="filter-sel-data-value center">{record.country}</div>
                          )
                          : null}
                        {checkColumnDisplay('Alliance Type')
                          ? (
                            <div className="filter-sel-data-value center">{record.alliance_type}</div>
                          )
                          : null}
                        {checkColumnDisplay('Networks')
                          ? (
                            <div className="filter-sel-data-value center">{record.networks && record.networks.length ? record.networks.join('; ') : '-'}</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Shortlisted On')
                          ? (
                            <div className="filter-sel-data-value center">{record.is_shortlisted.flag ? getFormattedDate(record.is_shortlisted.created_on) : '-'}</div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="lastside-column">
                      {(parse(location.search).compareFlag || IsComparisionClicked)
                        ? (
                          <div className="compare-checkbox">
                            <div className="checkbox-content">
                              <Checkbox
                                className={checkDefaultChecked(record.pi_id) ? 'check' : 'uncheck'}
                                key={record.pi_id}
                                onChange={(e) => onCheckboxChange(e, record.pi_id)}
                                checked={checkDefaultChecked(record.pi_id)}
                              />
                            </div>
                          </div>
                        ) : (
                          <Dropdown overlay={() => dataMenu(record)} trigger={['click']}>
                            <Button
                              className="accordion-menu"
                              role="presentation"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              <Tooltip title="Kebab menu">
                                <div className="accordion-menu-icon" />
                              </Tooltip>
                            </Button>
                          </Dropdown>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case 'country,pis': {
        return (
          <div className="country-runanalysis-wrap">
            <div className="card-content-title" style={{ display: 'flex', alignItems: 'center' }}>
              <a
                title={record.country}
                target="_blank"
                href={`/search/countryprofile?${stringify({
                  country: record.country,
                  type: 'COUNTRY',
                  projectId: parse(location.search).project_id,
                  analysisId: parse(location.search).analysis_id,
                })}`}
                rel="noreferrer noopener"
              >
                <div
                  className="country-name"
                >
                  <span className="country-name-title">{record.country[0].country}</span>
                </div>
              </a>
              <div
                className="country-name-no-arrow"
                role="presentation"
                onClick={() => {
                  if (showCountryHierarchy !== record.country) {
                    setShowCountryHierarchy(record.country);
                  } else {
                    setShowCountryHierarchy('');
                  }
                }}
              >
                <span className={`country-name-title ${showCountryHierarchy === record.country ? 'dropdown-close' : 'dropdown-open'}`}>
                  <span
                    className="dropdown-arrow"
                  />
                </span>
              </div>
            </div>
            {showCountryHierarchy === record.country && record.pis && record.pis.length
              ? (
                <>
                  {record.pis.map((rcd, indexv) => (
                    <div className="run-query-content-section" key={indexv}>
                      <div className="run-query-content-card card-country-content card">
                        <div className="pi-name-content">
                          <div className="pi-content">
                            <div className="body-analysis-section">
                              <div className="leftside-column">
                                <div className="meta-link">
                                  <div className="fav">
                                    <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(rcd.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(rcd.pi_id) !== -1 ? false : (rcd.isBookmarked && rcd.isBookmarked.flag)} type="investigators" id={rcd.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === rcd.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === rcd.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(rcd.pi_id) !== -1 ? '' : rcd.isBookmarked && rcd.isBookmarked.bookmark_id} />
                                  </div>
                                </div>
                                <a
                                  className="pi-content-title"
                                  title={rcd.pi_name}
                                  target="_blank"
                                  href={`/search/investigatorprofile?${stringify({
                                    query: rcd.pi_name,
                                    id: rcd.pi_id,
                                    type: 'PI',
                                  })}`}
                                  rel="noreferrer noopener"
                                >
                                  {rcd.pi_name}
                                </a>
                                {rcd.is_new
                                  ? (
                                    <div className="new-label">
                                      <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                    </div>
                                  )
                                  : null}
                                {rcd.compliance && rcd.compliance !== 'No Issues'
                                  ? (
                                    <div className={`${rcd.compliance === 'Compliance preventing participation' ? 'flag filled red' : rcd.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                      <Popover
                                        overlayClassName="flag-section-content"
                                        placement="bottom"
                                        title={<div className="flag-title">Compliance issue</div>}
                                        content={(
                                          <div className="flag-data">
                                            <div className="flag-data-title-create">
                                              <div className="flag-data-content-value">
                                                {rcd.compliance_issue_prevention_flag === 'Y'
                                                  ? (
                                                    <>
                                                      <div style={{ 'white-space': 'pre-line' }}>{rcd.compliance_issue_preventing_participation}</div>
                                                      <br />
                                                      <div style={{ 'white-space': 'pre-line' }}>{rcd.compliance_issues}</div>
                                                    </>
                                                  )
                                                  : (
                                                    <>
                                                      <div style={{ 'white-space': 'pre-line' }}>{rcd.compliance_issues}</div>
                                                    </>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        trigger="click"
                                      >
                                        <div className="flag-img" />
                                      </Popover>
                                    </div>
                                  )
                                  : null}
                              </div>
                              <div className="table-column">
                                <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                  {checkColumnDisplay('# Industry Studies')
                                    ? (
                                      <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(rcd.pi_id, rcd.industry_studies_source)}>{rcd.industry_studies}</Button>
                                    )
                                    : null}
                                  {checkColumnDisplay('Competing Studies')
                                    ? (
                                      <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(rcd.pi_id)}>{rcd.competing_studies}</Button>
                                    )
                                    : null}
                                  {checkColumnDisplay('PXL # Studies')
                                    ? (
                                      <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(rcd.pi_id)}>{rcd.pxl_studies}</Button>
                                    )
                                    : null}
                                  {checkColumnDisplay('Enrolled Subjects')
                                    ? (
                                      <div className="filter-sel-data-value enrolled-subjects">{rcd.enrolled_count}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Quality')
                                    ? (
                                      <div className="filter-sel-data-value quality">
                                        <Rate count={3} disabled value={rcd.quality_rating} />
                                      </div>
                                    )
                                    : null}
                                  {checkColumnDisplay('KOL Score')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.cls_kol_score}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Last Trial Start Date')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.recent_trial_start_date_ts ? getFormattedDate(rcd.recent_trial_start_date_ts * 1000) : '-'}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Center')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.site_name}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Country')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.country}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Alliance Type')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.alliance_type}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Networks')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.networks && rcd.networks.length ? rcd.networks.join('; ') : '-'}</div>
                                    )
                                    : null}
                                  {checkColumnDisplay('Last Shortlisted On')
                                    ? (
                                      <div className="filter-sel-data-value center">{rcd.is_shortlisted.flag ? getFormattedDate(rcd.is_shortlisted.created_on) : '-'}</div>
                                    )
                                    : null}
                                </div>
                              </div>
                              <div className="lastside-column">
                                {(parse(location.search).compareFlag || IsComparisionClicked)
                                  ? (
                                    <div className="compare-checkbox">
                                      <div className="checkbox-content">
                                        <Checkbox
                                          className={checkDefaultChecked(rcd.pi_id) ? 'check' : 'uncheck'}
                                          key={rcd.pi_id}
                                          onChange={(e) => onCheckboxChange(e, rcd.pi_id)}
                                          checked={checkDefaultChecked(rcd.pi_id)}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <Dropdown overlay={() => dataMenu(rcd)} trigger={['click']}>
                                      <Button
                                        className="accordion-menu"
                                        role="presentation"
                                        onClick={(e) => {
                                          e.preventDefault();
                                        }}
                                      >
                                        <Tooltip title="Kebab menu">
                                          <div className="accordion-menu-icon" />
                                        </Tooltip>
                                      </Button>
                                    </Dropdown>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        )
      }

      case 'country,primary_organizations,pis': {
        return (
          <div className="country-runanalysis-wrap">
            <div className="card-content-title" style={{ display: 'flex', alignItems: 'center' }}>
              <a
                title={record.country}
                target="_blank"
                href={`/search/countryprofile?${stringify({
                  country: record.country,
                  type: 'COUNTRY',
                  projectId: parse(location.search).project_id,
                  analysisId: parse(location.search).analysis_id,
                })}`}
                rel="noreferrer noopener"
              >
                <div
                  className="country-name"
                >
                  <span className="country-name-title">{record.country[0].country}</span>
                </div>
              </a>
              <div
                className="country-name-no-arrow"
                role="presentation"
                onClick={() => {
                  if (showCountryHierarchy !== record.country) {
                    setShowCountryHierarchy(record.country);
                  } else {
                    setShowCountryHierarchy('');
                  }
                }}
              >
                <span className={`country-name-title ${showCountryHierarchy === record.country ? 'dropdown-close' : 'dropdown-open'}`}>
                  <span
                    className="dropdown-arrow"
                  />
                </span>
              </div>
            </div>
            {showCountryHierarchy === record.country && record.primary_organizations && record.primary_organizations.length
              ? (
                <>
                  {record.primary_organizations.map((rcd, indexv) => (
                    <div className="run-query-content-section" key={indexv}>
                      <div className="run-query-content-card card-country-content card">
                        <div className="card-content card-country-content-header">
                          <div className="body-analysis-section level-header-section level-two-header-section">
                            <div className="leftside-column">
                              <div className="fav">
                                <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(rcd.site_id) !== -1 ? true : unbookmarkedIds.indexOf(rcd.site_id) !== -1 ? false : (rcd.isBookmarked && rcd.isBookmarked.flag)} type="centers" id={rcd.site_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === rcd.site_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === rcd.site_id)].innoplexus_id : unbookmarkedIds.indexOf(rcd.site_id) !== -1 ? '' : rcd.isBookmarked && rcd.isBookmarked.bookmark_id} />
                              </div>
                              <a
                                className="card-content-title"
                                title={rcd.site_name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: rcd.site_name,
                                  id: rcd.site_id,
                                  type: 'SITE',
                                  currentTab: rcd.parent_flag ? 'parent_centers' : 'centers',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {rcd.site_name}
                              </a>
                            </div>
                            <div className="table-column">
                              <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                {checkColumnDisplay('# Industry Studies')
                                  ? (
                                    <div className="filter-sel-data-value industry-studies">{rcd.industry_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Competing Studies')
                                  ? (
                                    <div className="filter-sel-data-value center">{rcd.competing_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('PXL # Studies')
                                  ? (
                                    <div className="filter-sel-data-value pxl-studies">{rcd.pxl_studies}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Enrolled Subjects')
                                  ? (
                                    <div className="filter-sel-data-value enrolled-subjects">{rcd.enrolled_count}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Quality')
                                  ? (
                                    <div className="filter-sel-data-value quality">
                                      <Rate count={3} disabled value={rcd.quality_rating} />
                                    </div>
                                  )
                                  : null}
                                {checkColumnDisplay('KOL Score')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Last Trial Start Date')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Center')
                                  ? (
                                    <div className="filter-sel-data-value center">{rcd.site_name}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Country')
                                  ? (
                                    <div className="filter-sel-data-value center">{rcd.country}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Alliance Type')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Networks')
                                  ? (
                                    <div className="filter-sel-data-value center">{rcd.networks && rcd.networks.length ? rcd.networks.join('; ') : '-'}</div>
                                  )
                                  : null}
                                {checkColumnDisplay('Last Shortlisted On')
                                  ? (
                                    <div className="filter-sel-data-value center">Not applicable</div>
                                  )
                                  : null}
                              </div>
                            </div>
                            <div className="lastside-column">
                              {(parse(location.search).compareSiteFlag || IsSiteComparisionClicked)
                                ? (
                                  <div className="compare-checkbox">
                                    <div className="checkbox-content">
                                      <Checkbox
                                        className={checkDefaultChecked(rcd.site_id) ? 'check' : 'uncheck'}
                                        onChange={(e) => onCheckboxChange(e, rcd.site_id)}
                                        checked={checkDefaultChecked(rcd.site_id)}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <Dropdown overlay={() => siteDataMenu()} trigger={['click']}>
                                    <Button
                                      className="accordion-menu"
                                      role="presentation"
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <Tooltip title="Kebab menu">
                                        <div className="accordion-menu-icon" />
                                      </Tooltip>
                                    </Button>
                                  </Dropdown>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="pi-name-content">
                          <ReadMore limit={5}>
                            {rcd.pis.map((itm, idx) => {
                              return (
                                <div className="pi-content" key={idx}>
                                  <div className="body-analysis-section">
                                    <div className="leftside-column">
                                      <div className="meta-link">
                                        <div className="fav">
                                          <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(itm.pi_id) !== -1 ? true : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? false : (itm.isBookmarked && itm.isBookmarked.flag)} type="investigators" id={itm.pi_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === itm.pi_id)].innoplexus_id : unbookmarkedIds.indexOf(itm.pi_id) !== -1 ? '' : itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                                        </div>
                                      </div>
                                      <a
                                        className="pi-content-title"
                                        title={itm.pi_name}
                                        target="_blank"
                                        href={`/search/investigatorprofile?${stringify({
                                          query: itm.pi_name,
                                          id: itm.pi_id,
                                          type: 'PI',
                                        })}`}
                                        rel="noreferrer noopener"
                                      >
                                        {itm.pi_name}
                                      </a>
                                      {itm.is_new
                                        ? (
                                          <div className="new-label">
                                            <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                          </div>
                                        )
                                        : null}
                                      {itm.compliance && itm.compliance !== 'No Issues'
                                        ? (
                                          <div className={`${itm.compliance === 'Compliance preventing participation' ? 'flag filled red' : itm.compliance === 'Compliance not preventing participation' ? 'flag filled light-red' : ''}`}>
                                            <Popover
                                              overlayClassName="flag-section-content"
                                              placement="bottom"
                                              title={<div className="flag-title">Compliance issue</div>}
                                              content={(
                                                <div className="flag-data">
                                                  <div className="flag-data-title-create">
                                                    <div className="flag-data-content-value">
                                                      {itm.compliance_issue_prevention_flag === 'Y'
                                                        ? (
                                                          <>
                                                            <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issue_preventing_participation}</div>
                                                            <br />
                                                            <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                          </>
                                                        )
                                                        : (
                                                          <>
                                                            <div style={{ 'white-space': 'pre-line' }}>{itm.compliance_issues}</div>
                                                          </>
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                              trigger="click"
                                            >
                                              <div className="flag-img" />
                                            </Popover>
                                          </div>
                                        )
                                        : null}
                                    </div>
                                    <div className="table-column">
                                      <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                        {checkColumnDisplay('# Industry Studies')
                                          ? (
                                            <Button className="filter-sel-data-value industry-studies" onClick={() => onIndustryClick(itm.pi_id, itm.industry_studies_source)}>{itm.industry_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('Competing Studies')
                                          ? (
                                            <Button className="filter-sel-data-value center" onClick={() => onCompetingClick(itm.pi_id)}>{itm.competing_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('PXL # Studies')
                                          ? (
                                            <Button className="filter-sel-data-value pxl-studies" onClick={() => onPxlClick(itm.pi_id)}>{itm.pxl_studies}</Button>
                                          )
                                          : null}
                                        {checkColumnDisplay('Enrolled Subjects')
                                          ? (
                                            <div className="filter-sel-data-value enrolled-subjects">{itm.enrolled_count}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Quality')
                                          ? (
                                            <div className="filter-sel-data-value quality">
                                              <Rate count={3} disabled value={itm.quality_rating} />
                                            </div>
                                          )
                                          : null}
                                        {checkColumnDisplay('KOL Score')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.cls_kol_score}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Last Trial Start Date')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.recent_trial_start_date_ts ? getFormattedDate(itm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Center')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.site_name}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Country')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.country}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Alliance Type')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.alliance_type}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Networks')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.networks && itm.networks.length ? itm.networks.join('; ') : '-'}</div>
                                          )
                                          : null}
                                        {checkColumnDisplay('Last Shortlisted On')
                                          ? (
                                            <div className="filter-sel-data-value center">{itm.is_shortlisted.flag ? getFormattedDate(itm.is_shortlisted.created_on) : '-'}</div>
                                          )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="lastside-column">
                                      {(parse(location.search).compareFlag || IsComparisionClicked)
                                        ? (
                                          <div className="compare-checkbox">
                                            <div className="checkbox-content">
                                              <Checkbox
                                                className={checkDefaultChecked(itm.pi_id) ? 'check' : 'uncheck'}
                                                key={idx}
                                                onChange={(e) => onCheckboxChange(e, itm.pi_id)}
                                                checked={checkDefaultChecked(itm.pi_id)}
                                              />
                                            </div>
                                          </div>
                                        ) : (
                                          <Dropdown overlay={() => dataMenu(itm)} trigger={['click']}>
                                            <Button
                                              className="accordion-menu"
                                              role="presentation"
                                              onClick={(e) => {
                                                e.preventDefault();
                                              }}
                                            >
                                              <Tooltip title="Kebab menu">
                                                <div className="accordion-menu-icon" />
                                              </Tooltip>
                                            </Button>
                                          </Dropdown>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </ReadMore>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )
              : null}
          </div>
        );
      }

      default: {
        return (
          <div>run analysis</div>
        );
      }
    }
  }

  return (
    <>
      {/* <Prompt
        when={displayLeaveConfirm}
        message="You did not save the latest run analysis results, are you sure you want to leave?"
      /> */}
      <div className="run-query-page">
        <div className="run-query-page-header">
          <div className="logo-backbtn-section ">
            <div className="landscape-logo">
              <div className="landscape-logo-img" />
            </div>
            <div className="back-button">
              <Button
                onClick={handleBackButton}
                tabIndex={0}
                role="button"
                onKeyDown={null}
                className="header-back-btn"
              >
                Back
              </Button>
            </div>
          </div>
          <div className="header-content-card-box">
            {renderQueries()}
          </div>
        </div>

        <div className="page-container run-query-page-container">
          {showAlertNotification
            ? (
              <div className="info-alert-box">
                <div className="info-alert-box-content">
                  <div className="info-alert-box-text">
                    Maximum 500 investigators based on # industry studies are shown here. Please click &#34;Save Analysis Results&#34; for system to save all investigators and centers qualifying the analysis criteria. You will be notified via email once the saved results are available at the click of the &#39;view&#39; icon on Analysis summary page.
                  </div>
                  <div className="alert-close-btn" role="presentation" onClick={() => setShowAlertNotification(false)} />
                </div>
              </div>
            )
            : null}
          <div className="card-details">
            <div className="card-details-header use-flex project-details-main">
              <div className="card-details-header-title card-details-project-header-title"><span className="title-name">Project Name:</span> <span className="desc">{parse(location.search).project_name}</span></div>
            </div>
            <div className="card-details-header use-flex project-details-main">
              <div className="card-details-header-title"><span className="title-name">Analysis:</span> <span className="project-name" title={parse(location.search).analysis_name}>{parse(location.search).analysis_name}</span></div>
              {/* <div className="project-detail-name">
                <div className="project-detail-name-value">
                  <span className="project-name"></span>
                </div>
              </div> */}
              <div className="project-data-meta-section analysis-meta-section-header">
                {/* uncomment this code later */}
                {/* <div className="check-mark-as-done-sec">
                  <div className="checkbox-content">
                    <div className="checkbox uncheck" />
                  </div>
                  <div className="check-text">Include results from prior queries</div>
                </div> */}
                {runAnalysisData.flag
                  ? (
                    <>
                      <div className="toggle-switch-wrap toggle-switch-wrap-relative">
                        <div
                          className={`toggle-switch-itm ${!showRunAnalysisMap ? 'active' : ''}`}
                          role="presentation"
                          onClick={() => setShowRunAnalysisMap(false)}
                        >
                          List
                        </div>
                        <div
                          className={`toggle-switch-itm ${showRunAnalysisMap ? 'active' : ''}`}
                          role="presentation"
                          onClick={() => setShowRunAnalysisMap(true)}
                        >
                          Map
                        </div>
                      </div>
                    </>
                  )
                  : null}
                <div className="sortby-icon">
                  {hierarchyOptions.filter((itm) => itm.value === reOrderHierarchy(hierarchy).split(',')[0])[0].title === 'Country'
                    ? (
                      <div className="sort-icon-img disabled" />
                    )
                    : (
                      <Popover
                        overlayClassName="sortby-runanalysis-popover"
                        placement="left"
                        title="Sort by:"
                        content={<SortBy pageName="runAnalysis" onApplySort={applySort} topHierarchyItm={hierarchyOptions.filter((itm) => itm.value === reOrderHierarchy(hierarchy).split(',')[0])[0].title} />}
                        trigger="click"
                        visible={isSortByPopoverVisible}
                        onVisibleChange={(visibled) => {
                          setIsSortByPopoverVisible(visibled);
                        }}
                      >
                        <Tooltip title="Sort">
                          <div className="sort-icon-img" />
                        </Tooltip>
                      </Popover>
                    )}
                </div>
                <div className="filter-icon">
                  <Popover
                    overlayClassName="filter-runanalysis-popover"
                    placement="right"
                    title="Filter By:"
                    content={(
                      <AnalysisFilters
                        pageName="runAnalysis"
                        analysisId={parse(location.search).analysis_id}
                        appliedFilters={appliedFilters}
                        onApplyFilters={applyFilters}
                        onReset={resetFilters}
                        enrolled_count_default={[runAnalysisData.data.min_enrolled_count, runAnalysisData.data.max_enrolled_count]}
                        industry_studies_default={[runAnalysisData.data.min_industry_studies, runAnalysisData.data.max_industry_studies]}
                      />
                    )}
                    trigger="click"
                    visible={isFilterPopoverVisible}
                    onVisibleChange={(visibled) => {
                      setIsFilterPopoverVisible(visibled);
                    }}
                  >
                    <Tooltip title="Filters">
                      <div className="filter-icon-img" />
                    </Tooltip>
                  </Popover>
                </div>
                {runAnalysisData.flag
                  ? (
                    <div className="fav">
                      <Bookmark inplace onBookmarkChange={onBookmarkChange} isBookmarked={bookmarkedIds.indexOf(parse(location.search).analysis_id) !== -1 ? true : unbookmarkedIds.indexOf(parse(location.search).analysis_id) !== -1 ? false : (runAnalysisData.data.isBookmarked && runAnalysisData.data.isBookmarked.flag)} type="analysis" id={parse(location.search).analysis_id} bookmark_id={createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === parse(location.search).analysis_id) !== -1 ? createdBookmarksAPIres[createdBookmarksAPIres.findIndex((bookmarked) => bookmarked.id === parse(location.search).analysis_id)].innoplexus_id : unbookmarkedIds.indexOf(parse(location.search).analysis_id) !== -1 ? '' : runAnalysisData.data.isBookmarked && runAnalysisData.data.isBookmarked.bookmark_id} />
                    </div>
                  )
                  : null}
              </div>
            </div>
            <Loader loading={runAnalysisData.loading} error={runAnalysisData.error}>
              {!showRunAnalysisMap
                ? (
                  <div className="project-data-wrap">
                    <div className="project-data-wrap-content-header">
                      <div className="project-data-wrap-content-header-filter">
                        <div className="left-section" />
                        <div className="middle-section">
                          <div className="search-bar-inner">
                            <div className="search-bar-input-sec">
                              <input className="search-bar-input" type="text" placeholder="Search PI" value={searchPIInput} onChange={onSearchPIInputChange} />
                              {displayingSearchPIResults
                                ? (
                                  <span className="close-icon-btn" role="presentation" onClick={() => onClearSearchPIInput()}>
                                    <span className="close-icon" />
                                  </span>
                                )
                                : null}
                              <span className={`search-bar-icon-btn ${!searchPIInput ? 'disabled' : ''}`} role="presentation" onClick={() => onPISearch()}>
                                <span className="search-bar-icon" />
                              </span>
                            </div>
                          </div>
                          <div className="hierarchy-multiselect-wrap">
                            <div className="hierarchy-multiselect-text">Levels of Hierarchy:&nbsp;</div>
                            <div className="hierarchy-multiselect">
                              <Select
                                popupClassName="hierarchy-multiselect-dropdowm"
                                mode="multiple"
                                value={hierarchy.split(',')}
                                maxTagCount={1}
                                onChange={handleHierarchyChange}
                              >
                                {hierarchyOptions.map((hierarchyLevel, index) => (
                                  <Option value={hierarchyLevel.value} key={index} disabled={index === 3 || (index === 1 && (hierarchy.split(',').indexOf('primary_organizations') === -1))}>
                                    {hierarchyLevel.title}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            {/* <div className="hierarchy-multiselect-text">&nbsp;of Investigator</div> */}
                          </div>
                          {runAnalysisData.flag && runAnalysisResults.length
                            ? (
                              <div className="show-doc-outof show-doc-outof-w-relative">
                                {getCountDetails()}
                              </div>
                            )
                            : null}
                        </div>
                        <div className="last-section">
                          <div className="show-hide-wrap">
                            <div className="show-hide-wrap">
                              <Popover
                                overlayClassName="filter-show-hide-wrap"
                                content={(
                                  <div>
                                    <ColumnFilters pageName="runAnalysis" applyColumns={applyColumns} />
                                  </div>
                                )}
                                title="Show/Hide Columns"
                                trigger="click"
                                visible={visible}
                                onVisibleChange={handleVisibleChange}
                              >
                                <Button className="show-hide-btn" type="primary">
                                  Show/Hide columns
                                </Button>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="project-data-wrap-content-header-filter-value">
                        <div className="header-analysis-section">
                          <div className="leftside-column" />
                          <div className="table-column">
                            <div id="table-column-header" className="table-column-sec table-column-header" onScroll={onHeaderScroll}>
                              {checkColumnDisplay('# Industry Studies')
                                ? (
                                  <div className="filter-sel-data-value industry-studies"># Industry Studies</div>
                                )
                                : null}
                              {checkColumnDisplay('Competing Studies')
                                ? (
                                  <div className="filter-sel-data-value center">Competing Studies</div>
                                )
                                : null}
                              {checkColumnDisplay('PXL # Studies')
                                ? (
                                  <div className="filter-sel-data-value pxl-studies">PXL # Studies</div>
                                )
                                : null}
                              {checkColumnDisplay('Enrolled Subjects')
                                ? (
                                  <div className="filter-sel-data-value enrolled-subjects">Enrolled Subjects</div>
                                )
                                : null}
                              {checkColumnDisplay('Quality')
                                ? (
                                  <div className="filter-sel-data-value quality">Quality</div>
                                )
                                : null}
                              {checkColumnDisplay('KOL Score')
                                ? (
                                  <div className="filter-sel-data-value center">KOL Score</div>
                                )
                                : null}
                              {checkColumnDisplay('Last Trial Start Date')
                                ? (
                                  <div className="filter-sel-data-value center">Last Trial Start Date</div>
                                )
                                : null}
                              {checkColumnDisplay('Center')
                                ? (
                                  <div className="filter-sel-data-value center">Center</div>
                                )
                                : null}
                              {checkColumnDisplay('Country')
                                ? (
                                  <div className="filter-sel-data-value center">Country</div>
                                )
                                : null}
                              {checkColumnDisplay('Alliance Type')
                                ? (
                                  <div className="filter-sel-data-value center">Alliance Type</div>
                                )
                                : null}
                              {checkColumnDisplay('Networks')
                                ? (
                                  <div className="filter-sel-data-value center">Networks</div>
                                )
                                : null}
                              {checkColumnDisplay('Last Shortlisted On')
                                ? (
                                  <div className="filter-sel-data-value center">Last Shortlisted On</div>
                                )
                                : null}
                            </div>
                          </div>
                          <div className="lastside-column" />
                        </div>
                      </div>
                    </div>
                    <LazyLoadDiv
                      className="card-list scrollbar"
                      id="centers-ra-list"
                      total={resultsTotal}
                      currentTotal={(runAnalysisResults || []).length}
                      loadMoreRows={() => loadMoreRows()}
                      height="calc(100vh - 517px)"
                    >
                      <div className="project-data-section">
                        {runAnalysisResults.map((record, index) => {
                          return (
                            <>
                              {renderAnalysisRecords(record, index)}
                            </>
                          )
                        })}
                        {runAnalysisData.flag && runAnalysisResults.length === 0
                          ? (
                            <div className="no-data-found-msg">
                              No relevant data found
                            </div>
                          )
                          : null}
                      </div>
                    </LazyLoadDiv>
                    {(parse(location.search).compareFlag || parse(location.search).compareSiteFlag || IsComparisionClicked || IsSiteComparisionClicked) && (
                      <div className="compare-card-footer">
                        <Button
                          disabled={!compareList.length}
                          className="compare-card-btn"
                          onClick={ResetCompare}
                        >
                          Reset
                        </Button>
                        <Button
                          disabled={(checkCompareEnable())}
                          className="compare-card-btn primary-btn"
                          onClick={(IsSiteComparisionClicked || parse(location.search).compareSiteFlag) ? handleOnSiteCompareClick : handleOnCompareClick}
                        >
                          Compare
                        </Button>
                      </div>
                    )}
                  </div>
                )
                : (
                  <div className="project-data-wrap">
                    <RunAnalysisMap data={runAnalysisData.data && runAnalysisData.data.map} project_id={parse(location.search).project_id} analysis_id={parse(location.search).analysis_id} />
                  </div>
                )}
            </Loader>
          </div>
        </div>
        {isSelectCommentTypeModalVisible
          ? (
            <Modal
              style={{ marginTop: '60px', width: '100%' }}
              title="Select comment type"
              visible={isSelectCommentTypeModalVisible}
              okText="Proceed"
              className="comment-type-modal-wrapper"
              onCancel={handleCommentCancel}
              onOk={handleCommentTypeOk}
              okType="primary"
              destroyOnClose
              runTimePropsComment={runTimePropsComment}
            >
              <Radio.Group onChange={onCommentTypeChange} value={commentTypeVal}>
                <Space direction="vertical">
                  <Radio value="specific">Comment Specific to this Analysis</Radio>
                  <Radio value="generic">Generic comment</Radio>
                </Space>
              </Radio.Group>
            </Modal>
          )
          : null}
        {displayComplianceConfirm
          ? (
            <Modal
              title="Compliance issue"
              visible={displayComplianceConfirm}
              okText="Yes"
              className="del-modal-wrapper conf-modal-wrapper"
              onCancel={() => {
                setCompliancePreventedPI({});
                setDisplayComplianceConfirm(false);
              }}
              onOk={() => {
                onShortlistClick(compliancePreventedPI);
                setCompliancePreventedPI({});
                setDisplayComplianceConfirm(false);
              }}
              okType="primary"
              destroyOnClose
            >
              <div className="modal-content">
                <div className="conf-desc">This investigator has a compliance issue preventing study participation.</div>
                <div className="process-text">Do you still want to add them to the shortlist?</div>
              </div>
            </Modal>
          )
          : null}
        {onItmClicked && <div><StudyModal onItmClicked={onItmClicked} setOnItmClicked={setOnItmClicked} clickedPId={clickedPId} flag="competing" /></div>}
        {onIndustryClicked && <div><StudyModal onItmClicked={onIndustryClicked} setOnItmClicked={setOnIndustryClicked} clickedPId={clickedIId} flag="industry" industryStudiesSource={industryStudiesSource} /></div>}
        {onPxlClicked && <div><StudyModal onItmClicked={onPxlClicked} setOnItmClicked={setOnPxlClicked} clickedPId={clickedPxId} flag="pxl" /></div>}
      </div>
    </>
  );
}
