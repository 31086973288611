import React, { useEffect, useState } from 'react'
import { Button, Rate, Popover, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { parse, stringify } from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import {
  getRunAnalysisAction,
  getRunAnalysisMoreAction,
  getRunAnalysisResetAction,
  getRunAnalysisCountsAction,
  getRunAnalysisCountsResetAction,
} from './logic';
import Loader from '../../../common/components/Loader';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv/index';
import Bookmark from '../../../common/components/Bookmark';
import ReadMore from '../../../common/components/ReadMore';
import ColumnFilters from '../RunAnalysis/columnFilters';
import AnalysisFilters from '../AnalysisFilters/index';
import './runquery.scss'
import { getFormattedDate } from '../../../utils';
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';

const keys = {
  '# Industry Studies': true,
  'Competing Studies': true,
  'PXL # Studies': true,
  'Enrolled Subjects': true,
  Quality: true,
  'KOL Score': true,
  'Last Trial Start Date': false,
  Center: false,
  Country: false,
  Networks: false,
  'Alliance Type': false,
  'Last Shortlisted On': false,
};

export default function RunQuery() {

  const runAnalysisData = useSelector((state) => state.runAnalysisData);
  const runAnalysisCountsData = useSelector((state) => state.runAnalysisCountsData);
  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const [fromVal, setFromVal] = useState(1);
  const [sizeVal, setSizeVal] = useState(25);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [mutuallyExclusiveChecked, setMutuallyExclusiveChecked] = useState(false);
  const [countsData, setCountsData] = useState({});
  const [visible, setVisible] = useState(false);
  const [displayColumns, setDisplayColumns] = useState({
    ...keys,
  })
  const [hierarchy, setHierarchy] = useState('primary_organizations,pis');
  const [showAlertNotification, setShowAlertNotification] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState({
    alliance: 'All',
    is_added: 'All',
    is_added_by_sponsor: 'All',
    is_commented: 'All',
    quality_rating: undefined,
    tier: undefined,
  })
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);

  const handleBackButton = () => {
    history.goBack();
  };

  useEffect(() => {
    if (createBookmark.flag) {
      setFromVal(1);
      dispatch(createBookmarkResetAction())
      dispatch(getRunAnalysisAction({
        from: 1,
        size: sizeVal,
        project_id: parse(location.search).project_id,
        query_id: parse(location.search).query_id,
        analysis_id: parse(location.search).analysis_id,
        is_mutually_execlusive: mutuallyExclusiveChecked,
        hierarchy,
        filters: JSON.stringify(appliedFilters),
      }));
    }
    if (deleteBookmark.flag) {
      setFromVal(1)
      dispatch(deleteBookmarkResetAction())
      dispatch(getRunAnalysisAction({
        from: 1,
        size: sizeVal,
        project_id: parse(location.search).project_id,
        query_id: parse(location.search).query_id,
        analysis_id: parse(location.search).analysis_id,
        is_mutually_execlusive: mutuallyExclusiveChecked,
        hierarchy,
        filters: JSON.stringify(appliedFilters),
      }));
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  useEffect(() => {
    return () => {
      dispatch(getRunAnalysisResetAction());
      dispatch(getRunAnalysisCountsResetAction());
      setSizeVal(25);
    };
  }, []);

  useEffect(() => {
    setHierarchy('primary_organizations,pis');
    dispatch(getRunAnalysisAction({
      from: 1,
      size: sizeVal,
      project_id: parse(location.search).project_id,
      query_id: parse(location.search).query_id,
      analysis_id: parse(location.search).analysis_id,
      is_mutually_execlusive: mutuallyExclusiveChecked,
      hierarchy,
      filters: JSON.stringify(appliedFilters),
    }));

    dispatch(getRunAnalysisCountsAction({
      query_id: (parse(location.search).query_id),
      analysis_id: (parse(location.search).analysis_id),
      is_mutually_execlusive: mutuallyExclusiveChecked,
    }));
  }, []);

  useEffect(() => {
    if (runAnalysisCountsData.flag) {
      setCountsData(runAnalysisCountsData.data.data[0]);
    }
  }, [JSON.stringify(runAnalysisCountsData)]);


  const loadMoreRows = () => {
    setFromVal(fromVal + 1);
    dispatch(getRunAnalysisMoreAction({
      from: fromVal + 1,
      size: sizeVal,
      project_id: parse(location.search).project_id,
      query_id: (parse(location.search).query_id),
      is_mutually_execlusive: mutuallyExclusiveChecked,
      analysis_id: (parse(location.search).analysis_id),
      hierarchy,
    }));
  }

  const applyColumns = (columnsObj) => {
    setDisplayColumns(columnsObj);
    setVisible(false);
  };

  const checkColumnDisplay = (columnName) => {
    const viewedKeys = Object.keys(displayColumns).filter((key) => displayColumns[key]);
    return viewedKeys.indexOf(columnName) !== -1;
  }

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const onHeaderScroll = () => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = source.scrollLeft;
    });
  }

  const onRowScroll = (e) => {
    const source = document.getElementById('table-column-header');
    const targets = document.querySelectorAll('.table-column-body');
    targets.forEach((item) => {
      item.scrollLeft = e.target.scrollLeft;
    });
    source.scrollLeft = e.target.scrollLeft;
  }

  const onMutuallyExclusiveClick = () => {
    dispatch(getRunAnalysisAction({
      from: 1,
      size: sizeVal,
      project_id: parse(location.search).project_id,
      query_id: (parse(location.search).query_id),
      analysis_id: (parse(location.search).analysis_id),
      is_mutually_execlusive: !mutuallyExclusiveChecked,
      hierarchy,
      filters: JSON.stringify(appliedFilters),
    }));
    dispatch(getRunAnalysisCountsAction({
      query_id: (parse(location.search).query_id),
      analysis_id: (parse(location.search).analysis_id),
      is_mutually_execlusive: !mutuallyExclusiveChecked,
    }));
    setFromVal(1);
    setMutuallyExclusiveChecked(!mutuallyExclusiveChecked);
  }

  const applyFilters = (filtersObj) => {
    setAppliedFilters({ ...appliedFilters, ...filtersObj });
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      from: 1,
      size: sizeVal,
      project_id: parse(location.search).project_id,
      query_id: parse(location.search).query_id,
      analysis_id: parse(location.search).analysis_id,
      filters: JSON.stringify({ ...appliedFilters, ...filtersObj }),
      is_mutually_execlusive: mutuallyExclusiveChecked,
      hierarchy,
    }))
    setIsFilterPopoverVisible(false);
  }

  const resetFilters = () => {
    const temp = {
      alliance: 'All',
      is_added: 'All',
      is_commented: 'All',
      quality_rating: undefined,
      tier: undefined,
    };
    setAppliedFilters(temp);
    setFromVal(1);
    dispatch(getRunAnalysisAction({
      from: 1,
      size: sizeVal,
      project_id: parse(location.search).project_id,
      query_id: parse(location.search).query_id,
      analysis_id: parse(location.search).analysis_id,
      filters: JSON.stringify(temp),
      is_mutually_execlusive: mutuallyExclusiveChecked,
      hierarchy,
    }))
    setIsFilterPopoverVisible(false);
  }
  return (
    <div className="run-query-page">
      <div className="run-query-page-header">
        <div className="logo-backbtn-section ">
          <div className="landscape-logo">
            <div className="landscape-logo-img" />
          </div>
          <div className="back-button">
            <Button
              onClick={handleBackButton}
              tabIndex={0}
              role="button"
              onKeyDown={null}
              className="header-back-btn"
            >
              Back
            </Button>
          </div>
        </div>
        <div className="header-card-content">
          {countsData
            ? (
              <div className="card">
                <div className="card-content">
                  <div className="card-content-value">
                    <div className="card-content-value-title">Query name:</div>
                    <div className="card-content-value-desc" title={countsData.query}>{countsData.query}</div>
                  </div>
                  <div className="card-content-value">
                    <div className="card-content-value-title">Centers:</div>
                    <div className="card-content-value-desc">{countsData.centers}</div>
                  </div>
                  <div className="card-content-value">
                    <div className="card-content-value-title">Pls:</div>
                    <div className="card-content-value-desc">{countsData.pis}</div>
                  </div>
                </div>
              </div>
            )
            : null}
        </div>
      </div>
      <div className="page-container run-query-page-container">
        {showAlertNotification
          ? (
            <div className="info-alert-box">
              <div className="info-alert-box-content">
                <div className="info-alert-box-text">
                  Maximum 500 investigators based on # industry studies are shown here. Please run complete analysis and &#34;Save Analysis Results&#34; for system to save all investigators and centers qualifying the entire analysis criteria. You will be notified via email once the saved results are available at the click of the &#39;view&#39; icon on Analysis summary page.
                </div>
                <div className="alert-close-btn" role="presentation" onClick={() => setShowAlertNotification(false)} />
              </div>
            </div>
          )
          : null}
        <div className="card-details">
          <div className="card-details-header use-flex project-details-main">
            <div className="card-details-header-title card-details-project-header-title"><span className="title-name">Project Name:</span> <span className="desc">{parse(location.search).project_name}</span></div>
          </div>
          <div className="card-details-header use-flex project-details-main">
            <div className="card-details-header-title card-details-analysis-header-title">
              <span className="title-name">Analysis Name:</span>
              <span className="desc">{parse(location.search).analysis_name}</span>
            </div>
          </div>

          <div className="card-details-header use-flex project-details-main">
            <div className="card-details-header-title run-query-icon"><span className="title-name">Query:</span> <span className="project-name" title={parse(location.search).query_name}>{parse(location.search).query_name}</span></div>
            {/* <div className="project-detail-name">
              <div className="project-detail-name-value">
                <span className="project-name">{parse(location.search).query_name}</span>
              </div>
            </div> */}
            <div className="project-data-meta-section analysis-meta-section-header">
              {parse(location.search).showMutEx === 'true'
                ? (
                  <div className="check-mark-as-done-sec" role="presentation" onClick={() => onMutuallyExclusiveClick()}>
                    <div className="checkbox-content">
                      <div className={`checkbox ${mutuallyExclusiveChecked ? 'check' : 'uncheck'}`} />
                    </div>
                    <div className="check-text">Show mutually exclusive results</div>
                  </div>
                )
                : null}
              <div className="filter-icon">
                <Popover
                  overlayClassName="filter-runanalysis-popover"
                  placement="left"
                  title="Filter by:"
                  content={(
                    <AnalysisFilters
                      pageName="runQueryPage"
                      analysisId={parse(location.search).analysis_id}
                      appliedFilters={appliedFilters}
                      onApplyFilters={applyFilters}
                      onReset={resetFilters}
                      enrolled_count_default={[runAnalysisData.data.min_enrolled_count, runAnalysisData.data.max_enrolled_count]}
                      industry_studies_default={[runAnalysisData.data.min_industry_studies, runAnalysisData.data.max_industry_studies]}
                    />
                  )}
                  trigger="click"
                  visible={isFilterPopoverVisible}
                  onVisibleChange={(visibled) => {
                    setIsFilterPopoverVisible(visibled);
                  }}
                >
                <Tooltip title="Filters">
                  <div className="filter-icon-img" />
                  </Tooltip>
                </Popover>
              </div>
            </div>
          </div>
          <Loader loading={runAnalysisData.loading} error={runAnalysisData.error}>
            <div className="project-data-wrap">
              <div className="project-data-wrap-content-header">
                <div className="project-data-wrap-content-header-filter">
                  <div className="middle-section">
                    {runAnalysisData.flag && runAnalysisData.data.data.length
                      ? (
                        <div className="show-doc-outof show-doc-outof-w-relative">
                          <div className="show-doc-data">
                            Showing
                            <span className="show-count-number">{runAnalysisData.data.totalPiCount}</span>investigators
                          </div>
                        </div>
                      )
                      : null}
                  </div>
                  <div className="last-section">
                    <div className="show-hide-wrap">
                      <div className="show-hide-wrap">
                        <Popover
                          overlayClassName="filter-show-hide-wrap"
                          content={(
                            <div>
                              <ColumnFilters pageName="runQueryPage" applyColumns={applyColumns} />
                            </div>
                          )}
                          title="Show/Hide Columns"
                          trigger="click"
                          visible={visible}
                          onVisibleChange={handleVisibleChange}
                        >
                          <Button className="show-hide-btn" type="primary">
                            Show/Hide columns
                          </Button>
                        </Popover>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="project-data-wrap-content-header-filter-value">
                  <div className="header-analysis-section">
                    <div className="leftside-column" />
                    <div className="table-column">
                      <div id="table-column-header" className="table-column-sec table-column-header" onScroll={onHeaderScroll}>
                        {checkColumnDisplay('# Industry Studies')
                          ? (
                            <div className="filter-sel-data-value industry-studies"># Industry Studies</div>
                          )
                          : null}
                        {checkColumnDisplay('Competing Studies')
                          ? (
                            <div className="filter-sel-data-value center">Competing Studies</div>
                          )
                          : null}
                        {checkColumnDisplay('PXL # Studies')
                          ? (
                            <div className="filter-sel-data-value pxl-studies">PXL # Studies</div>
                          )
                          : null}
                        

                        {checkColumnDisplay('Enrolled Subjects')
                          ? (
                            <div className="filter-sel-data-value enrolled-subjects">Enrolled Subjects</div>
                          )
                          : null}
                        {checkColumnDisplay('Quality')
                          ? (
                            <div className="filter-sel-data-value quality">Quality</div>
                          )
                          : null}
                        {checkColumnDisplay('KOL Score')
                          ? (
                            <div className="filter-sel-data-value center">KOL Score</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Trial Start Date')
                          ? (
                            <div className="filter-sel-data-value center">Last Trial Start Date</div>
                          )
                          : null}
                        {checkColumnDisplay('Center')
                          ? (
                            <div className="filter-sel-data-value center">Center</div>
                          )
                          : null}
                        {checkColumnDisplay('Country')
                          ? (
                            <div className="filter-sel-data-value center">Country</div>
                          )
                          : null}
                        {checkColumnDisplay('Alliance Type')
                          ? (
                            <div className="filter-sel-data-value center">Alliance Type</div>
                          )
                          : null}
                        {checkColumnDisplay('Networks')
                          ? (
                            <div className="filter-sel-data-value center">Networks</div>
                          )
                          : null}
                        {checkColumnDisplay('Last Shortlisted On')
                          ? (
                            <div className="filter-sel-data-value center">Last Shortlisted On</div>
                          )
                          : null}
                      </div>
                    </div>
                    <div className="lastside-column" />
                  </div>
                </div>
              </div>
              <LazyLoadDiv
                className="card-list scrollbar"
                id="centers-rq-list"
                total={runAnalysisData.data.total}
                currentTotal={(runAnalysisData?.data?.data || []).length}
                loadMoreRows={() => loadMoreRows()}
                height="calc(100vh - 460px)"
              >
                <div className="project-data-section">
                  {runAnalysisData?.data?.data && runAnalysisData?.data?.data.length > 0 && runAnalysisData?.data?.data.map((record) => {
                    return (
                      <div className="run-query-content-section">
                        <div className="run-query-content-card card-country-content card">
                          <div className="card-content card-country-content-header">
                            <div className="leftside-column">
                              <a
                                className="card-content-title"
                                title={record.site_name}
                                target="_blank"
                                href={`/search/deepdives?${stringify({
                                  query: record.site_name,
                                  id: record.site_id,
                                  type: 'SITE',
                                  currentTab: record.parent_flag ? 'parent_centers' : 'centers',
                                })}`}
                                rel="noreferrer noopener"
                              >
                                {record.site_name}
                              </a>
                            </div>
                            <div className="table-column " />
                          </div>
                          <div className="pi-name-content">
                            <ReadMore limit={5}>
                              {record.pis.map((itm) => {
                                return (
                                  <div className="pi-content">
                                    <div className="body-analysis-section">
                                      <div className="leftside-column">
                                        <div className="meta-link">
                                          <div className="fav">
                                            <Bookmark isBookmarked={itm.isBookmarked && itm.isBookmarked.flag} type="investigators" id={itm.pi_id} bookmark_id={itm.isBookmarked && itm.isBookmarked.bookmark_id} />
                                          </div>
                                        </div>
                                        <a
                                          className="pi-content-title"
                                          title={itm.pi_name}
                                          target="_blank"
                                          href={`/search/investigatorprofile?${stringify({
                                            query: itm.pi_name,
                                            id: itm.pi_id,
                                            type: 'PI',
                                          })}`}
                                          rel="noreferrer noopener"
                                        >
                                          {itm.pi_name}
                                        </a>
                                        {itm.is_new
                                          ? (
                                            <div className="new-label">
                                              <div className="new-label-icon" title="This investigator was not part of the results earlier" />
                                            </div>
                                          )
                                          : null}
                                        {/* <div className="comments">
                                          <Comment
                                            projectId={parse(location.search).project_id}
                                            analysisId={parse(location.search).analysis_id}
                                            piId={itm.pi_id}
                                            type="pi"
                                            sitePIExtraType="specific"
                                            count={JSON.stringify(itm.comments)}
                                          />
                                        </div> */}
                                      </div>
                                      <div className="table-column">
                                        <div className="table-column-sec table-column-body" onScroll={onRowScroll}>
                                          {checkColumnDisplay('# Industry Studies')
                                            ? (
                                              <div className="filter-sel-data-value industry-studies">{itm.industry_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Competing Studies')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.competing_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('PXL # Studies')
                                            ? (
                                              <div className="filter-sel-data-value pxl-studies">{itm.pxl_studies}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Enrolled Subjects')
                                            ? (
                                              <div className="filter-sel-data-value enrolled-subjects">{itm.enrolled_count}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Quality')
                                            ? (
                                              <div className="filter-sel-data-value quality">
                                                <Rate count={3} disabled value={itm.quality_rating} />
                                              </div>
                                            )
                                            : null}
                                          {checkColumnDisplay('KOL Score')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.cls_kol_score}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Last Trial Start Date')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.recent_trial_start_date_ts ? getFormattedDate(itm.recent_trial_start_date_ts * 1000) : '-'}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Center')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.site_name}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Country')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.country}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Alliance Type')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.alliance_type}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Networks')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.networks && itm.networks.length ? itm.networks.join('; ') : '-'}</div>
                                            )
                                            : null}
                                          {checkColumnDisplay('Last Shortlisted On')
                                            ? (
                                              <div className="filter-sel-data-value center">{itm.is_shortlisted.flag ? getFormattedDate(itm.is_shortlisted.created_on) : '-'}</div>
                                            )
                                            : null}
                                        </div>
                                      </div>
                                      <div className="lastside-column" />
                                    </div>
                                  </div>
                                )
                              })}
                            </ReadMore>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {runAnalysisData.flag && runAnalysisData?.data?.data.length === 0
                    ? (
                      <div className="no-data-found-msg">
                        No relevant data found
                      </div>
                    )
                    : null}
                </div>
              </LazyLoadDiv>
            </div>
          </Loader>
        </div>
      </div>
    </div>
  );
}
