import { stringify } from 'query-string';
import { GENERIC_ACTION } from '../SearchResults/logic'

const SHORTLIST_PI = 'SHORTLIST_PI'
const SHORTLIST_PI_SUCCESS = 'SHORTLIST_PI_SUCCESS'
const SHORTLIST_PI_RESET = 'SHORTLIST_PI_RESET'
const SHORTLIST_PI_FAILURE = 'SHORTLIST_PI_FAILURE'

const DELETE_SHORTLISTED_PI = 'DELETE_SHORTLISTED_PI'
const DELETE_SHORTLISTED_PI_SUCCESS = 'DELETE_SHORTLISTED_PI_SUCCESS'
const DELETE_SHORTLISTED_PI_RESET = 'DELETE_SHORTLISTED_PI_RESET'
const DELETE_SHORTLISTED_PI_FAILURE = 'DELETE_SHORTLISTED_PI_FAILURE'

const GET_ANALYSIS_FILTER = 'GET_ANALYSIS_FILTER';
const GET_ANALYSIS_FILTER_SUCCESS = 'GET_ANALYSIS_FILTER_SUCCESS';
const GET_ANALYSIS_FILTER_RESET = 'GET_ANALYSIS_FILTER_RESET';
const GET_ANALYSIS_FILTER_FAILURE = 'GET_ANALYSIS_FILTER_FAILURE';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const shortlistPiAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: SHORTLIST_PI,
    url: `${process.env.apiUrl}v0/shortlist/createshortlistPI`,
    method: 'POST',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'PI shortlisted successfully',
  hideNotification: false, // hide notification on error
})

export const shortlistPiResetAction = () => ({
  type: SHORTLIST_PI_RESET,
})

export const deleteshortlistedPiAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload: payload.body,
  meta: {
    actionType: DELETE_SHORTLISTED_PI,
    url: `${process.env.apiUrl}v0/shortlist/deleteshortlistPI?${stringify(payload.params)}`,
    method: 'DELETE',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: true, // show notification on success
  message: 'PI removed from shortlisted PIs',
  hideNotification: false, // hide notification on error
})

export const deleteshortlistedPiResetAction = () => ({
  type: DELETE_SHORTLISTED_PI_RESET,
})

export const getAnalysisFilterAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_ANALYSIS_FILTER,
    url: `${process.env.apiUrl}v0/search/runanalysisfilters`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getAnalysisFilterResetAction = () => ({
  type: GET_ANALYSIS_FILTER_RESET,
})

export function shortlistPiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHORTLIST_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case SHORTLIST_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case SHORTLIST_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case SHORTLIST_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function deleteshortlistedPiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_SHORTLISTED_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_SHORTLISTED_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_SHORTLISTED_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case DELETE_SHORTLISTED_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getAnalysisFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ANALYSIS_FILTER: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_ANALYSIS_FILTER_SUCCESS: {
      if (action.meta.filter_type) {
        return {
          data: {
            ...state.data, [action.meta.filter_type]: action.payload,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_ANALYSIS_FILTER_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_ANALYSIS_FILTER_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
